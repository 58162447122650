import { Form, Popconfirm, Upload, message } from 'antd'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PinImageWrapper } from './styled'
import { DeleteTwoTone, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react';
import { apiUrl } from '../../../utils/config';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Slider, Modal } from "antd";
import { isImage, uploadImage } from '../../../utils/projectLib';
import { RotateLeftOutlined, RotateRightOutlined, RedoOutlined } from "@ant-design/icons";
import ControlButton from "./ControlButton.js";

function PinImage({ authStore, adminStore, imageUrl, setImageUrl, propertiesName }) {
    const [filename, setFileName] = useState('')
    const [imageCropper, setImageCropper] = useState(null)
    const [scale, setScale] = useState(1);
    const [cropper, setCropper] = useState(null);
    const cropperRef = useRef(null);
    const uploadButton = () => {
        return (
            <Fragment>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </Fragment>
        )
    }

    const handleUploadPinImage = file => {
        if (!file) return;
        setFileName(file.name)
        const reader = new FileReader();
        reader.onload = e => {
            adminStore.setIsShowCropperImage(true)
            setImageCropper(e.target.result)
        };
        reader.readAsDataURL(file);
    }

    const clearProjectImage = () => {
        setImageUrl(null)
        setImageCropper(null)
    }

    const DeleteProjectImage = () => {
        return (
            <Popconfirm
                okText={'Delete'}
                cancelText={'Cancel'}
                title={'Are you sure'}
                okType='danger'
                zIndex={9999}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => clearProjectImage()}
            >
                <DeleteTwoTone className='delete-icon' style={{ fontSize: 16, backgroundColor: 'whitesmoke', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10 }} />
            </Popconfirm>
        )
    }

    const onRotate = direction => () => {
        let angle = 0;
        let angleConfig = {
            left: -30,
            right: 30,
        };
        angle = angleConfig[direction] ?? 0;
        cropperRef.current.cropper.rotate(angle);
    };

    const onScale = value => {
        const scaleValue = parseFloat(value);
        setScale(scaleValue);
        cropperRef.current.cropper.scale(scaleValue);
    };

    const onReset = () => {
        const cropper = cropperRef.current.cropper;
        cropper.reset();
        setScale(1);
    };

    const onUpload = async () => {
        return new Promise((resolve, reject) => {
            try {
                const data = cropperRef.current.cropper.getCroppedCanvas();
                data.toBlob(async blob => {
                    const formData = new FormData();
                    formData.append('file', blob, filename);
                    const res = await uploadImage(formData, authStore.token);
                    if (res?.data?.filename) {
                        setImageUrl(isImage(res?.data?.filename) ? res?.data?.filename : null);
                    }
                    handleCancel()
                    resolve(true)
                });

            } catch (err) {
                adminStore.openNotification(
                    "bottomRight",
                    "error",
                    'Upload image failed!'
                );
                reject(false)
            }
        })
    };

    const handleCancel = () => {
        adminStore.setIsShowCropperImage(false);
        cropperRef.current.cropper.reset();
    };
    useEffect(() => {
        if (imageCropper) {
            setCropper(<Cropper
                src={imageCropper}
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={16 / 9}
                guides={false}
                ref={cropperRef}
            />);
        }
    }, [imageCropper])
    return (
        <PinImageWrapper>
            <Modal
                title="Cropper Image"
                zIndex={9999}
                open={adminStore.isShowCropperImage && imageCropper}
                onOk={onUpload}
                onCancel={handleCancel}
                maskClosable={false}
                className='modal-add-folder'
            >
                <div className={'imageEditor'}>
                    <div>
                        {cropper}
                        <div className={'controlsBlock'}>
                            <ControlButton tooltip="Rotate Left" onClick={onRotate("left")}>
                                <RotateLeftOutlined size={30} />
                            </ControlButton>
                            <ControlButton tooltip="Reset" onClick={onReset}>
                                <RedoOutlined size={30} />
                            </ControlButton>
                            <ControlButton tooltip="Rotate Right" onClick={onRotate("right")}>
                                <RotateRightOutlined size={30} />
                            </ControlButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <Form.Item
                label="Image"
                name={[`${propertiesName}`]}
                valuePropName="file">
                <Upload
                    accept='image/*'
                    showUploadList={false}
                    name="avatar"
                    className={`upload-image`}
                    beforeUpload={file => handleUploadPinImage(file)}
                    listType="picture-card"
                >
                    {
                        imageUrl
                            ? <img crossOrigin="anonymous" src={`${apiUrl}/files/${imageUrl}`} alt="" className='input-upload-image-project' />
                            : uploadButton()
                    }
                </Upload>
                {imageUrl && <DeleteProjectImage />}
            </Form.Item>
        </PinImageWrapper>
    )
}

export default inject("adminStore", "authStore")(observer(PinImage));