import { Row, Col } from "antd";
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ContainerWrapper } from "./style";
const Cesium = require('cesium')
const CameraPosition = ({ viewer, projectStore }) => {

    useEffect(() => {
        if (!viewer) return
        const scene = viewer.scene;
        const canvas = viewer.canvas;
        const handler = new Cesium.ScreenSpaceEventHandler(canvas);
        if (!scene.pickPositionSupported) {
            window.alert("This browser does not support pickPosition.");
        }
        handler.setInputAction(function (movement) {
            const camposition = viewer.scene.camera.position.clone();
            const camera = viewer.scene.camera;
            if (camposition) {
                const cartographic = Cesium.Cartographic.fromCartesian(
                    camposition
                );
                const headingString = camera.heading.toFixed(7);
                const pitchString = camera.pitch.toFixed(7);
                const rollString = camera.roll.toFixed(7);
                const heightString = cartographic.height.toFixed(7);
                const latitude = Cesium.Math.toDegrees(cartographic.latitude).toFixed(7);
                const longitude = Cesium.Math.toDegrees(cartographic.longitude).toFixed(7);
                projectStore.setCameraPosition({
                    heading: `${`${headingString}`}`,
                    pitch: `${`${pitchString}`}`,
                    roll: `${`${rollString}`}`,
                    height: `${`${heightString}`}`,
                    lat: `${`${latitude}`}`,
                    long: `${`${longitude}`}`
                })
            }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        handler.setInputAction(function (movement) {
            const camposition = viewer.scene.camera.position.clone();
            const camera = viewer.scene.camera;
            if (camposition) {
                const cartographic = Cesium.Cartographic.fromCartesian(
                    camposition
                );
                const headingString = camera.heading.toFixed(7);
                const pitchString = camera.pitch.toFixed(7);
                const rollString = camera.roll.toFixed(7);
                const heightString = cartographic.height.toFixed(7);
                const latitude = Cesium.Math.toDegrees(cartographic.latitude).toFixed(7);
                const longitude = Cesium.Math.toDegrees(cartographic.longitude).toFixed(7);
                projectStore.setCameraPosition({
                    heading: `${`${headingString}`}`,
                    pitch: `${`${pitchString}`}`,
                    roll: `${`${rollString}`}`,
                    height: `${`${heightString}`}`,
                    lat: `${`${latitude}`}`,
                    long: `${`${longitude}`}`,
                })
            }
        }, Cesium.ScreenSpaceEventType.WHEEL);
    }, [])

    return (
        <ContainerWrapper>
            <Row className="container">
                <div className='position'>
                    
                    <Row span={24} className="container-content">
                    {/* <Col span={24} className="container-label">Camera:</Col> */}
                            <span>Camera:</span>
                        <Col className='pos-item'>
                            <span>Heding:</span>
                            <span>{projectStore.cameraPosition?.heading}</span>
                        </Col>
                        <Col className='pos-item'>
                            <span>Pitch:</span>
                            <span>{projectStore.cameraPosition?.pitch}</span>
                        </Col>
                        <Col className='pos-item'>
                            <span>Roll:</span>
                            <span>{projectStore.cameraPosition?.roll}</span>
                        </Col>
                        <Col className='pos-item'>
                            <span>Lat:</span>
                            <span>{projectStore.cameraPosition?.lat}</span>
                        </Col>
                        <Col className='pos-item'>
                            <span>Lon:</span>
                            <span>{projectStore.cameraPosition?.long}</span>
                        </Col>
                        <Col className='pos-item'>
                            <span>Height:</span>
                            <span>{projectStore.cameraPosition?.height}</span>
                        </Col>
                    </Row>
                </div>
            </Row>
        </ContainerWrapper>
    );
}

export default inject('projectStore')(observer(CameraPosition));

