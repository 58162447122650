import { Col, Form, Input, InputNumber, Modal, Checkbox, Select, Row, message } from "antd";
import { useEffect, useState } from "react";
import GroupItem from "../../AdminDashboard/GroupItem";
import TextArea from "antd/lib/input/TextArea";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
const { Option } = Select;
const ModalAddModel = ({ adminStore, setModelsProject, projectId, setModelsFolder, folderId, setModelsSubFolder, subFolderId, setModelsSubSubFolder, subSubFolderId, tokenIon }) => {
     const [checked, setChecked] = useState(true);
     const [allModel, setAllModel] = useState([]);
     const [projects, setProjects] = useState([]);
     const [ionIds, setIonIds] = useState([]);
     const [isSelectIon, setIsSelectIon] = useState(false);
     const [isToken, setIsToken] = useState(false);
     const [addModelForm] = Form.useForm();
     const location = useLocation();
     let pathname = location.pathname;
     useEffect(() => {
          const fetchData = async () => {
               if (tokenIon) {
                    adminStore.getIonIds(tokenIon).then(res => {
                         if (res?.length > 0) {
                              setIonIds(res)
                              setIsToken(true)
                         } else {
                              message.config({
                                   top: 80,
                                   duration: 2,
                              });
                              message.error("Project Ion token does not exist");
                              setIsToken(false);
                         }
                    })
               } else {
                    setIonIds([])
                    setIsToken(false);
               }
               await adminStore.getAllModel3DS(1, 1000).then(res => {
                    setAllModel(res.docs)
               })
               adminStore.getAllProjects(1, 100).then((res) => {
                    setProjects(res.docs);
               });
          }
          fetchData()
          return () => {
               addModelForm.resetFields();
               setChecked(true)
               setIonIds([])
               setProjects([])
               setIsToken(false)
               setIsSelectIon(false)
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [adminStore.openModelModal])

     const cancelAddModel = () => {
          adminStore.setOpenModelModal(false);
          addModelForm.resetFields();
          setChecked(true)
     };
     const onChangeCheckbox = (e) => {
          setChecked(e.target.checked)
     };
     const createModel3d = async (payload) => {
          adminStore.setLoadingProgress(true);
          await adminStore.createNewModel3DS(payload).then((response) => {
               if (pathname.includes("projects")) {
                    setModelsProject(model3ds => [...model3ds, response]);
               }
               if (pathname.includes("/folders/")) {
                    setModelsFolder(model3ds => [...model3ds, response])
               }
               if (pathname.includes("/subfolders/")) {
                    setModelsSubFolder(model3ds => [...model3ds, response])
               }
               if (pathname.includes("sub-subfolders")) {
                    setModelsSubSubFolder(model3ds => [...model3ds, response])
               }
               cancelAddModel();
               adminStore.setLoadingProgress(false);
               adminStore.openNotification('bottomRight', 'success', 'Create new model3d successfully!');
          })
               .catch((err) => {
                    adminStore.setLoadingProgress(false);
                    adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
               });
     };
     const handleCreateNewModel = () => {
          addModelForm.validateFields().then(async response => {
               if (response.useExisting) {
                    await adminStore.getModelById(response.model).then(async (res) => {
                         const payload = {
                              title: res?.title || "",
                              desc: res?.desc || "",
                              ionAccess: res?.ionAccess || null,
                              type: res?.type,
                              sourceType: res?.sourceType,
                              height: res?.height,
                              fill: {
                                   color: res?.fill?.color,
                                   opacity: res?.fill?.opacity
                              },
                              stroke: {
                                   width: res?.stroke?.width,
                                   color: res?.stroke?.color,
                                   opacity: res?.stroke?.opacity
                              }
                         };
                         if (projectId) {
                              payload.projectId = projectId
                         } else if (folderId) {
                              payload.categoryId = folderId
                         } else if (subFolderId) {
                              payload.subCategoryId = subFolderId
                         } else if (subSubFolderId) {
                              payload.subCategoryChecklistId = subSubFolderId
                         }
                         await createModel3d(payload)
                    })
               } else {
                    const payload = {
                         title: response?.title || "",
                         desc: response?.desc || "",
                         ionAccess: response?.ionAccess || null,
                         type: response?.type,
                         sourceType: response?.sourceType,
                         height: response?.height,
                         fill: {
                              color: response?.fillcolor,
                              opacity: response?.fillopacity
                         },
                         stroke: {
                              width: response?.strokewidth,
                              color: response?.strokecolor,
                              opacity: response?.strokeopacity
                         }
                    };
                    if (projectId) {
                         payload.projectId = projectId
                    } else if (folderId) {
                         payload.categoryId = folderId
                    } else if (subFolderId) {
                         payload.subCategoryId = subFolderId
                    } else if (subSubFolderId) {
                         payload.subCategoryChecklistId = subSubFolderId
                    }
                    await createModel3d(payload)
               }
          })
     };
     return (
          <Modal
               title={`Add model into ${pathname.includes("projects") ? "project" : pathname.includes("/folders/") ? "folder" : pathname.includes("/subfolders/") ? "sub folder" : pathname.includes("sub-subfolders") ? "sub-subfolder" : ""}`}
               zIndex={9999}
               open={adminStore.openModelModal}
               onOk={handleCreateNewModel}
               onCancel={cancelAddModel}
               maskClosable={false}
               className='modal-add-folder'
          >
               <Form
                    name="edit-model3d"
                    form={addModelForm}
                    layout="vertical"
                    labelAlign="left"
                    style={{ width: '100%' }}
               >
                    <Form.Item
                         name="useExisting"
                         valuePropName="checked"
                         initialValue={true}
                         rules={[
                              {
                                   required: false,
                              },
                         ]}
                    >
                         <Checkbox checked={checked} onChange={onChangeCheckbox}>Use existing model</Checkbox>
                    </Form.Item>
                    {
                         checked ? (
                              <Form.Item
                                   label={"Model"}
                                   name="model"
                                   rules={[
                                        {
                                             required: true,
                                             message:
                                                  "Please select model",
                                        },
                                   ]}
                              >
                                   <Select
                                        placeholder={
                                             "Please select model"
                                        }
                                        showSearch
                                        allowClear={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                             option.children
                                                  .toLowerCase()
                                                  .indexOf(input.toLowerCase()) >= 0
                                        }
                                   >
                                        {
                                             allModel.map((v, i) => {
                                                  return (
                                                       <Option key={v.id} value={v.id}>
                                                            {v.title}
                                                       </Option>
                                                  );
                                             })}
                                   </Select>
                              </Form.Item>
                         ) :
                              <Row >
                                   <Col span={24}>
                                        <Form.Item name={["title"]} label="Title" rules={[
                                             {
                                                  required: true,
                                                  message: "Please input title",
                                             },
                                        ]}>
                                             <Input className="form-input" placeholder="Title" />
                                        </Form.Item>
                                   </Col>
                                   <Col span={24}>
                                        <Form.Item
                                             label={"Project"}
                                             name="projectId"
                                             rules={[
                                                  {
                                                       required: false,
                                                       message:
                                                            "Please select a project",
                                                  },
                                             ]}
                                        >
                                             <Select
                                                  showSearch
                                                  placeholder={
                                                       "Please select a project"
                                                  }
                                                  allowClear={true}
                                                  optionFilterProp="children"
                                                  filterOption={(input, option) =>
                                                       option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                  }
                                             >
                                                  {projects.map((v, i) => {
                                                       return (
                                                            <Option key={v.id} value={v.id}>
                                                                 {v.name}
                                                            </Option>
                                                       );
                                                  })}
                                             </Select>
                                        </Form.Item>
                                   </Col>
                                   <Col span={24}>
                                        <Form.Item name={["desc"]} label="Description" rules={[
                                             {
                                                  required: false,
                                                  message: "Please input description",
                                             },
                                        ]}>
                                             <TextArea rows={4} className="form-input" placeholder="Please input description" />
                                        </Form.Item>
                                   </Col>
                                   <Col span={24}>
                                        <Form.Item
                                             name={["type"]}
                                             label="Type"
                                             rules={[
                                                  {
                                                       required: true,
                                                  },
                                             ]}
                                        >
                                             <Select
                                                  options={[
                                                       { value: 'ion', label: 'ION 3DTiles' },
                                                       { value: 'geojson', label: 'ION Geojson' },
                                                       { value: 'czml', label: 'Czml' }
                                                  ]}
                                             />
                                        </Form.Item>
                                        <Form.Item
                                             label={"IonAccess"}
                                             name="ionAccess"
                                             rules={[
                                                  {
                                                       required: false,
                                                       message:
                                                            "Please select a ionid",
                                                  },
                                             ]}
                                        >
                                             <Select
                                                  onFocus={() => {
                                                       setIsSelectIon(true)
                                                  }}
                                                  showSearch
                                                  placeholder={
                                                       "Please select a ionid"
                                                  }
                                                  allowClear={true}
                                                  optionFilterProp="children"
                                                  filterOption={(input, option) =>
                                                       option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                  }
                                             >
                                                  {ionIds.map((v, i) => {
                                                       return (
                                                            <Option key={v.id} value={v.id}>
                                                                 {`${v.name} (${v.id})`}
                                                            </Option>
                                                       );
                                                  })}
                                             </Select>
                                        </Form.Item>
                                             {!isToken && isSelectIon && <p style={{ color: 'red' }}>Project Ion token does not exist</p>}
                                   </Col>
                                   <Col span={24}>
                                        <Form.Item
                                             name={["sourceType"]}
                                             label="SourceType"
                                             rules={[
                                                  {
                                                       required: true,
                                                  },
                                             ]}
                                        >
                                             <Select
                                                  options={[
                                                       {
                                                            value: "local",
                                                            label: "Local",
                                                       },
                                                       {
                                                            value: "external",
                                                            label: "External",
                                                       },
                                                  ]}
                                             />
                                        </Form.Item>
                                        <Form.Item name={"height"} label="Height">
                                             <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                   </Col>
                                   <GroupItem title={"Stroke"}>
                                        <Col span={24}>
                                             <Form.Item name={"strokewidth"} label="Witdh">
                                                  <InputNumber style={{ width: "100%" }} />
                                             </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                             <Form.Item name={["strokecolor"]} label="color">
                                                  <input
                                                       style={{ width: '150px', height: '150px' }}
                                                       type="color"
                                                  />
                                             </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                             <Form.Item name={"strokeopacity"} label="Opacity">
                                                  <InputNumber max={1} min={0} style={{ width: "100%" }} />
                                             </Form.Item>
                                        </Col>
                                   </GroupItem>
                                   <GroupItem title={"Fill"}>
                                        <Col span={24}>
                                             <Form.Item name={"fillcolor"} label="Color">
                                                  <input
                                                       style={{ width: '150px', height: '150px' }}
                                                       type="color"
                                                  />
                                             </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                             <Form.Item name={["fillopacity"]} label="Opacity">
                                                  <InputNumber max={1} min={0} style={{ width: "100%" }} />
                                             </Form.Item>
                                        </Col>
                                   </GroupItem>
                              </Row>
                    }

               </Form>
          </Modal>
     )
}

export default inject("userStore", "adminStore", "authStore")(observer(ModalAddModel));