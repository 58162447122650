import { SaveOutlined } from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Row, Tooltip } from 'antd';
import { JulianDate } from 'cesium';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ButtonCustom, ButtonWrappers, ContainerWrappers, Schedule } from './style';

const ShadowSchedule = ({ projectStore, viewer }) => {
    const [currentViewingTime, setCurrentViewingTime] = useState(projectStore.currentViewingTime);
    const [enableShadows, setEnableShadows] = useState(false);
    function startReRender() {
        if (viewer && viewer._cesiumWidget) {
            //if (viewer.scene.requestRenderMode) { viewer.scene.requestRender(); }
        }
    }
    useEffect(() => {
        if (projectStore.isShowShadowModal) {
            setCurrentViewingTime(projectStore.currentViewingTime)
            setEnableShadows(projectStore.enableShadows)
        }
    }, [projectStore.isShowShadowModal])

    useEffect(() => {
        if (currentViewingTime && enableShadows) {
            if (projectStore.isShowShadowModal && currentViewingTime && currentViewingTime.toDate) {
                viewer.clockViewModel.clock.currentTime = JulianDate.fromDate(currentViewingTime.toDate());
                viewer.clockViewModel.synchronize();
                startReRender()
            }
        }
    }, [currentViewingTime])

    useEffect(() => {
        if (viewer) {
            viewer.shadows = enableShadows
            if (currentViewingTime && enableShadows) {
                if (currentViewingTime.toDate) {
                    viewer.clockViewModel.clock.currentTime = JulianDate.fromDate(currentViewingTime.toDate());
                    viewer.clockViewModel.synchronize();
                    startReRender()
                }
            }
        }
        startReRender()
    }, [enableShadows])

    const onChangeCheck = (e) => {
        setEnableShadows(e.target.checked)
    }

    const handleSetTime = (value) => {
        setCurrentViewingTime(value)
    }

    const handleCancel = () => {
        projectStore.setEnableShadows(projectStore.enableShadows)
        projectStore.setCurrentViewingTime(projectStore.currentViewingTime)
        projectStore.setShowShadowModal(false)
    }

    const handleSave = () => {
        projectStore.setEnableShadows(enableShadows)
        projectStore.setCurrentViewingTime(currentViewingTime)
        projectStore.setShowShadowModal(false)
    }

    return (
        <Schedule>
            <Row className="close-btn" span={24}>
                <Tooltip zIndex={9999} title='Close' placement="left">
                    <span onClick={() => handleCancel()}>X</span>
                </Tooltip>
            </Row>
            <ContainerWrappers>
                <Row>
                    <Col>
                        <strong>Shadow schedule</strong>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col >
                        Shadow:
                    </Col>
                    <Col >
                        <Checkbox checked={enableShadows} onChange={onChangeCheck} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Time:
                    </Col>
                </Row>
                <Row>
                    <div className="timeSearch-datePicker">
                        <DatePicker
                            inputReadOnly={true}
                            defaultValue={currentViewingTime}
                            showTime
                            placeholder={'Select time'}
                            onChange={handleSetTime}
                            onOk={handleSetTime}
                            style={{ minWidth: 0 }}
                            allowClear={false}
                        />
                    </div>
                </Row>
                <ButtonWrappers justify='end'>
                    <Col>
                        <ButtonCustom
                            icon={<SaveOutlined />}
                            onClick={handleCancel}>
                            Cancel
                        </ButtonCustom>
                    </Col>
                    <Col>
                        <ButtonCustom
                            icon={<SaveOutlined />}
                            type="primary"
                            onClick={handleSave}>
                            Save
                        </ButtonCustom>
                    </Col>
                </ButtonWrappers>
            </ContainerWrappers>
        </Schedule>
    )
}
export default inject('projectStore', 'projectStore')(observer(ShadowSchedule))