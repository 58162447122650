import { Row } from "antd";
import { Ion,
    Cartesian3, Color, Math as CesiumMath, IonResource, ScreenSpaceEventHandler, ScreenSpaceEventType,
    JulianDate,
    Cartesian4,
    PostProcessStageLibrary,
    ProviderViewModel,
    createDefaultImageryProviderViewModels, buildModuleUrl
} from "cesium";
import CesiumNavigation from "cesium-navigation-es6";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { PostProcessStage, Viewer } from "resium";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import MainMenu from "./Menu";
import RightContainer from "./RightContainer";
import { ProjectWrapper } from "./style";
import { useParams } from "react-router-dom";

import MeasureDistance from "../../components/helper/MeasureDistance";
import FlyWalk from "./FlyWalk/FlyWalk";
import ToolbarRight from "../../components/layout/Toolbar/ToolbarRight";
import OptimizeControl from "./OptimizeControl";
import CameraPosition from "./CameraPosition";
import ShadowSchedule from "./ShadowSchedule";
import geojsonBbox from "geojson-bbox";
import pinLocationIcon from '../../assets/images/pinlocation.png';
import ModalAtrribute from "./ModalAtrribute";
import SkyBoxControl from "./SkyBoxControl";
import BloomControl from "./BloomControl";
import FogControl from "./FogControl";
import EnhancementEchniquesControl from "./EnhancementEchniquesControl";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
const Cesium = require('cesium')

let countClick = 0;
let draggerHandler = null;
const defaultDuration = 4;
const ProjectDetail = (props) => {
    const viewerRef = useRef(null)
    const { authStore, projectStore, userStore } = props;
    const [measureDistance, setMeasureDistance] = useState(false);
    const [flyWalk, setFlyWalk] = useState(false);
    const [subCategories, setSubCategories] = useState([])
    const [skyBoxControl, setSkyBoxControl] = useState(false);
    const { projectId } = useParams()
    const [bloomControl, setBloomControl] = useState(false);
    const [fogControl, setFogControl] = useState(false);
    const [enhancementControl, setEnhancementControl] = useState(false);
    const [fogView, setFogView] = useState(false)
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const checkShowWidgets = (widget) => {
        let show = false
        const widgetsControl = projectStore.widgetsControl
        if (widgetsControl) {
            show = widgetsControl[widget]
        }
        return show
    }
    const handleLeftClick = (click) => {
        const viewer = viewerRef.current.cesiumElement;
        let pickedObjects = viewer.scene.drillPick(click.position);
        if (countClick > 1 && isMobile) {
            if (pickedObjects && pickedObjects.length > 0) {
                for (var i = 0; i < pickedObjects.length; i++) {
                    let item = pickedObjects[i];
                    if (item && item.id && item.id?.billboard) {
                        let billboard = item.id
                        let billboardPosition = billboard.position._value;
                        let ellipsoid = viewer.scene.globe.ellipsoid;
                        let cartographic = ellipsoid.cartesianToCartographic(billboardPosition);
                        let longitude = billboard?.propertiesPin?.location?.long ? billboard?.propertiesPin?.location?.long : Cesium.Math.toDegrees(cartographic.longitude);
                        let latitude = billboard?.propertiesPin?.location?.lat ? billboard?.propertiesPin?.location?.lat : Cesium.Math.toDegrees(cartographic.latitude);
                        let height = billboard?.propertiesPin?.location?.height ? billboard?.propertiesPin?.location?.height : cartographic.height;
                        var boundingSphere = new Cesium.BoundingSphere(Cesium.Cartesian3.fromDegrees(longitude, latitude, height), 1);
                        viewer.camera.flyToBoundingSphere(boundingSphere, {
                            duration: defaultDuration,
                            offset: {
                                heading: billboard?.propertiesPin?.orientation?.heading ? billboard?.propertiesPin?.orientation?.heading : Cesium.Math.toRadians(0),
                                pitch: billboard?.propertiesPin?.orientation?.pitch ? billboard?.propertiesPin?.orientation?.pitch : Cesium.Math.toRadians(-90),
                                roll: billboard?.propertiesPin?.orientation?.roll ? billboard?.propertiesPin?.orientation?.roll : Cesium.Math.toRadians(0)
                            }
                        });
                        if (billboard?._properties) {
                            const excludeList = ["stroke",
                                "stroke-width",
                                "stroke-opacity",
                                "fill",
                                "fill-opacity"];
                            const rightData = {
                                title: "Properties",
                                properties: {
                                }
                            }
                            for (let i = 0; i < billboard._properties.propertyNames.length; i++) {
                                const key = billboard._properties.propertyNames[i]
                                if (excludeList.indexOf(key) > -1) {
                                    continue
                                }
                                const lot = billboard._properties[key];
                                if (lot && lot._value) {
                                    rightData.properties[key] = lot._value
                                }
                            }
                            projectStore.setRightData(getDescription(rightData))
                        }
                        break;
                    }
                }
            }
        } else if (countClick === 1) {
            projectStore.setRightData([])
            let objectInfo
            const pickedObject = pickedObjects[0]
            if (pickedObject) {
                if (pickedObject.id) {
                    if (pickedObject.id?.key) {
                        objectInfo = pickedObject.id
                    } else {
                        const object = pickedObject.id
                        if (object._properties) {
                            const excludeList = ["stroke",
                                "stroke-width",
                                "stroke-opacity",
                                "fill",
                                "fill-opacity"];
                            const rightData = {
                                title: "Properties",
                                properties: {
                                }
                            }
                            for (let i = 0; i < object._properties.propertyNames.length; i++) {
                                const key = object._properties.propertyNames[i]
                                if (excludeList.indexOf(key) > -1) {
                                    continue
                                }
                                const lot = object._properties[key];
                                if (lot && lot._value) {
                                    rightData.properties[key] = lot._value
                                }
                            }
                            projectStore.setRightData(getDescription(rightData))
                            // const lot = object._properties['Lot n° '];
                            // if (lot && lot._value) {
                            //     // projectStore.setShowModalAtrribute({
                            //     //     title: "Properties",
                            //     //     properties: {
                            //     //         "Lot n°": lot._value
                            //     //     }
                            //     // })
                            //     projectStore.setRightData(getDescription({
                            //         title: "Properties",
                            //         properties: {
                            //             "Lot n°": lot._value
                            //         }
                            //     }))
                            // }
                        }
                    }
                } else if (pickedObject.primitive?.key) {
                    objectInfo = pickedObject.primitive
                }
            }

            if (objectInfo && projectStore.viewMode === 'Default mode' && !projectStore.flyWalkMode && projectStore.projectDetail) {
                let element
                const category = projectStore.projectDetail?.categories;
                if (objectInfo.key.includes("-pinLocation")) {
                    if (objectInfo.key.includes("-category")) {
                        for (let i = 0; i < category.length; i++) {
                            if (objectInfo.key.slice(0, objectInfo.key.indexOf("-pinLocation")) === category[i].id) {
                                const key = objectInfo.key.slice(`${category[i].id}-pinLocation-`.length, objectInfo.key.indexOf("-category"));
                                if (key) {
                                    element = searchElement(category[i].pin, "id", key)
                                }
                            }
                        }
                    }
                    if (objectInfo.key.includes("subFolder")) {
                        category.forEach((folder) => {
                            folder.subcategories?.forEach((subfolder) => {
                                if (objectInfo.key.slice(0, objectInfo.key.indexOf("-pinLocation")) === subfolder.id) {
                                    const key = objectInfo.key.slice(`${subfolder.id}-pinLocation-`.length, objectInfo.key.indexOf("-subFolder"));
                                    if (key) {
                                        element = searchElement(subfolder.pin, "id", key)
                                    }
                                }
                            })
                        })
                    }
                    if (objectInfo.key.includes("sub2Folder")) {
                        category.forEach((folder) => {
                            folder.subcategories?.forEach((subfolder) => {
                                subfolder.subcategorychecklists.forEach((subsubfolder) => {
                                    if (objectInfo.key.slice(0, objectInfo.key.indexOf("-pinLocation")) === subsubfolder.id) {
                                        const key = objectInfo.key.slice(`${subsubfolder.id}-pinLocation-`.length, objectInfo.key.indexOf("-sub2Folder"));
                                        if (key) {
                                            element = searchElement(subsubfolder.pin, "id", key)
                                        }
                                    }
                                })
                            })
                        })
                    }
                } else if (objectInfo.key.includes("-no-control-access")) {
                    const pinIndex = objectInfo.key.indexOf("-no-control-access")
                    const key = objectInfo.key.slice(0, pinIndex)
                    if (key) {
                        element = searchElement(projectStore.projectDetail.pin, "id", key)
                    }
                } else {
                    element = searchElement(projectStore.projectDetail.categories, "id", objectInfo.key)
                }
                // projectStore.setShowModalAtrribute(element)
                projectStore.setRightData(getDescription(element))
            }
        }
        countClick = 0;
    }
    var silhouetteGreen = PostProcessStageLibrary.createEdgeDetectionStage()
    silhouetteGreen.uniforms.color = Color.LIME
    silhouetteGreen.uniforms.length = 0.01
    var extent = Cesium.Rectangle.fromDegrees(-8.287401, 33.284300, -6.967191, 34.267182);
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = extent;
    Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
    if (viewerRef?.current?.cesiumElement) {
        viewerRef.current.cesiumElement.baseLayerPicker.viewModel.terrainProviderViewModels.pop();
        viewerRef.current.cesiumElement.baseLayerPicker.viewModel.terrainProviderViewModels.shift();
        const shadowMap = viewerRef?.current?.cesiumElement.shadowMap;
        if (shadowMap) {
            shadowMap.darkness = 0.5;
            // shadowMap.maximumDistance = 10000.0;
        }
    }

    function startReRender() {
        if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement._cesiumWidget) {
            if (viewerRef.current.cesiumElement.scene.requestRenderMode) { viewerRef.current.cesiumElement.scene.requestRender(); }
        }
    }

    const getDescription = (element) => {
        const data = element?.properties || {}
        let result = []
        let dataButton = {}
        for (const property in data) {
            if (property === "dataSource") {
                result.push({
                    key: property,
                    table: data[property]
                })

            } else if (property === "gallery" || property === "video" || property === "pdf") {
                dataButton[property] = data[property]
            } else {
                result.push({
                    key: property,
                    value: data[property]
                })
            }
        }
        result.push({
            key: "dataButton",
            button: dataButton
        })
        return result
    }

    const loadFuntionCesium = () => {
        if (draggerHandler) {
            draggerHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
            draggerHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
        }
        const viewer = viewerRef.current.cesiumElement;
        const options = {};
        options.enableCompass = true;
        options.enableZoomControls = false;
        options.enableDistanceLegend = true;
        options.enableCompassOuterRing = true;
        viewer.scene.debugShowFramesPerSecond = false
        new CesiumNavigation(viewer, options);
        const newDate = new Date();
        newDate.setUTCHours(6, 0, 0, 0);
        const currentTime = Cesium.JulianDate.fromDate(newDate);
        const endTime = Cesium.JulianDate.addHours(
            currentTime,
            16,
            new Cesium.JulianDate()
        );
        viewer.clock.currentTime = currentTime;
        viewer.timeline.zoomTo(currentTime, endTime);
        // if (!isMobile && !Cesium.Fullscreen.fullscreen) {
        //     Cesium.Fullscreen.requestFullscreen(document.body)
        // }
        viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
            function (e) {
                e.cancel = true;
                flyHome()
            });

        draggerHandler = new ScreenSpaceEventHandler(viewer.canvas);
        const debouncedHandleLeftClick = debounce((click) => handleLeftClick(click), 500);
        draggerHandler.setInputAction((click) => {
            countClick++
            debouncedHandleLeftClick(click);
        }, ScreenSpaceEventType.LEFT_CLICK);

        draggerHandler.setInputAction(function (click) {
            viewer.trackedEntity = undefined;
            let pickedObjects = viewer.scene.drillPick(click.position);
            if (pickedObjects && pickedObjects.length > 0) {
                for (var i = 0; i < pickedObjects.length; i++) {
                    let item = pickedObjects[i];
                    if (item && item.id && item.id?.billboard) {
                        let billboard = item.id
                        let billboardPosition = billboard.position._value;
                        let ellipsoid = viewer.scene.globe.ellipsoid;
                        let cartographic = ellipsoid.cartesianToCartographic(billboardPosition);
                        let longitude = billboard?.propertiesPin?.location?.long ? billboard?.propertiesPin?.location?.long : Cesium.Math.toDegrees(cartographic.longitude);
                        let latitude = billboard?.propertiesPin?.location?.lat ? billboard?.propertiesPin?.location?.lat : Cesium.Math.toDegrees(cartographic.latitude);
                        let height = billboard?.propertiesPin?.location?.height ? billboard?.propertiesPin?.location?.height : cartographic.height;
                        var boundingSphere = new Cesium.BoundingSphere(Cesium.Cartesian3.fromDegrees(longitude, latitude, height), 1);
                        viewer.camera.flyToBoundingSphere(boundingSphere, {
                            duration: defaultDuration,
                            offset: {
                                heading: billboard?.propertiesPin?.orientation?.heading ? billboard?.propertiesPin?.orientation?.heading : Cesium.Math.toRadians(0),
                                pitch: billboard?.propertiesPin?.orientation?.pitch ? billboard?.propertiesPin?.orientation?.pitch : Cesium.Math.toRadians(-90),
                                roll: billboard?.propertiesPin?.orientation?.roll ? billboard?.propertiesPin?.orientation?.roll : Cesium.Math.toRadians(0)
                            }
                        });
                        if (billboard?._properties) {
                            const excludeList = ["stroke",
                                "stroke-width",
                                "stroke-opacity",
                                "fill",
                                "fill-opacity"];
                            const rightData = {
                                title: "Properties",
                                properties: {
                                }
                            }
                            for (let i = 0; i < billboard._properties.propertyNames.length; i++) {
                                const key = billboard._properties.propertyNames[i]
                                if (excludeList.indexOf(key) > -1) {
                                    continue
                                }
                                const lot = billboard._properties[key];
                                if (lot && lot._value) {
                                    rightData.properties[key] = lot._value
                                }
                            }
                            projectStore.setRightData(getDescription(rightData))
                        }
                        break;
                    }
                }
            }
        }, ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    }
    function waitViewerReady() {
        const waitUntilReady = async () => {
            if (viewerRef.current?.cesiumElement) {
                loadFuntionCesium()
                return true
            } else {
                setTimeout(waitUntilReady, 500);
            }
        };
        (async () => {
            await waitUntilReady();
        })();
    }
    useEffect(() => {
        const fetchProjectData = async () => {
            await waitViewerReady()
            if (projectId) {
                await projectStore.getProjectDetail(projectId).then(response => {
                    if (response.czmlUrl || response.czmlPath) {
                        projectStore.setCZML(response.czmlPath, response.czmlUrl)
                    }
                    const widgetsControl = response.widgetsControl;
                    projectStore.setWidgetsControl(widgetsControl ? widgetsControl : {})
                }).catch(async () => {
                    await projectStore.getProjectDefaultDetail().then(response => {
                        if (response.czmlUrl || response.czmlPath) {
                            projectStore.setCZML(response.czmlPath, response.czmlUrl)
                        }
                        const widgetsControl = response.widgetsControl;
                        projectStore.setWidgetsControl(widgetsControl ? widgetsControl : {})
                    })
                })
            } else {
                await projectStore.getProjectDefaultDetail().then(response => {
                    if (response.czmlUrl || response.czmlPath) {
                        projectStore.setCZML(response.czmlPath, response.czmlUrl)
                    }
                    const widgetsControl = response.widgetsControl;
                    projectStore.setWidgetsControl(widgetsControl ? widgetsControl : {})
                })
            }

        }
        fetchProjectData()
        return () => {
            projectStore.setActiveElement({})
            projectStore.setListModelView([])
            projectStore.setProjectDetail({})
            projectStore.setActiveElement({})
            projectStore.setModelLists([])
            projectStore.setVisibleModels([])
            projectStore.setActiveElementItem(false)
            setMeasureDistance(false)
            projectStore.setViewMode('Default mode')
            projectStore.setViewCameraFly(false)
            projectStore.setCheckedElement(false)
            projectStore.resetWidgetsControl()
            if (draggerHandler) {
                draggerHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                draggerHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
            }
        }
    }, [])

    const searchElement = (data, key, match) => {
        const stack = [];
        data.map(item => stack.push(item));
        while (stack.length > 0) {
            const node = stack.pop();
            if (node[key] === match) {
                return node;
            } else if (node.subcategories) {
                node.subcategories.map(child => stack.push(child))
            }
            else if (node.subcategorychecklists) {
                node.subcategorychecklists.map(child => stack.push(child))
            }
        }
        return null;
    }
    const getElement = (data) => {
        const stack = [];
        const elements = [];
        data.map(item => stack.push(item));
        while (stack.length > 0) {
            const node = stack.shift();
            if (node && node.type && node.type !== 'place') {
                elements.push(toJS(node))
            }
            if (node.subcategories) {
                node.subcategories.map(child => stack.push(child))
            }
            if (node.subcategorychecklists) {
                node.subcategorychecklists.map(child => stack.push(child))
            }
        }
        return elements;
    }

    useEffect(() => {
        if (projectStore.projectDetail) {
            if (projectStore.projectDetail.token) {
                Ion.defaultAccessToken = projectStore.projectDetail.token
            }
            if (projectStore.projectDetail?.startView) {
                document.title = projectStore.projectDetail.name;
                const lat = projectStore.projectDetail?.startView.lat
                const lon = projectStore.projectDetail?.startView.long
                const height = projectStore.projectDetail?.startView.height
                const heading = projectStore.projectDetail?.startView.heading
                const pitch = projectStore.projectDetail?.startView.pitch
                const roll = projectStore.projectDetail?.startView.roll
                const flyOption = {
                    duration: defaultDuration,
                    destination: Cartesian3.fromDegrees(lon, lat, height),
                    orientation: {
                        heading: heading,
                        pitch: pitch,
                        roll: roll
                    },
                };
                const viewer = viewerRef.current.cesiumElement
                viewer.scene.camera.flyTo(flyOption);
            }
            if (projectStore.projectDetail?.ionTerrain) {
                drawTerrainProject(projectStore.projectDetail.ionTerrain)
            }
            if (projectStore.projectDetail?.categories && projectStore.projectDetail?.categories?.length > 0) {
                let model3dsProject = projectStore.projectDetail?.model3ds ? projectStore.projectDetail?.model3ds.filter(c => !c.subCategoryId) : []
                Promise.all(model3dsProject.map(async element => {
                    try {
                        const elm = await fetchData(element, true)
                        return elm
                    }
                    catch {
                        return {
                            error: "notfoundresource"
                        }
                    }
                }))
                let model3ds = [];
                projectStore.projectDetail.categories.map(item => {
                    if (item.model3ds?.length > 0) {
                        model3ds = model3ds.concat(item.model3ds)
                    }
                    if (item.subcategories?.length > 0) {
                        item.subcategories.map(subitem => {
                            if (subitem.model3ds?.length > 0) {
                                let newModel3ds = subitem.model3ds.map(model => {
                                    model.categoryId = item.id
                                    return model
                                })
                                model3ds = model3ds.concat(newModel3ds)
                            }
                            if (subitem.subcategorychecklists.length > 0) {
                                subitem.subcategorychecklists.map(subsubitem => {
                                    if (subsubitem.model3ds?.length > 0) {
                                        let newModel3ds = subsubitem.model3ds.map(model => {
                                            model.categoryId = item.id
                                            model.subCategoryId = subitem.id
                                            return model
                                        })
                                        model3ds = model3ds.concat(newModel3ds)
                                    }
                                })
                            }
                        })
                    }
                })
                Promise.all(model3ds.map(async element => {
                    try {
                        const elm = await fetchData(element, false, true)
                        return elm
                    }
                    catch {
                        return {
                            error: "notfoundresource"
                        }
                    }
                }))
                const defaultActive = projectStore.projectDetail?.categories[0]
                if (defaultActive) {
                    projectStore.setActiveElement(defaultActive)
                    const subData = Array.isArray(defaultActive?.subcategories) ?
                        JSON.parse(JSON.stringify(defaultActive?.subcategories))?.sort((a, b) => (a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)) : [];
                    setSubCategories(subData)
                }
                const elements = getElement(projectStore.projectDetail.categories)
                const pins = getPins(projectStore.projectDetail.categories)
                if (projectStore.projectDetail) {
                    drawNoAccessPin(projectStore.projectDetail)
                }
                if (pins && pins.length > 0) {
                    drawPin(pins)
                }
                projectStore.setModelLists(elements)
            }
        }
        return () => {
            document.title = 'Zenata pixland';
        };
    }, [projectStore.projectDetail])

    useEffect(() => {
        if (!viewerRef.current) return
        if (!viewerRef.current.cesiumElement) return
        const viewer = viewerRef.current.cesiumElement
        var resolutionScale = Number(projectStore.renderResolutionValue);
        resolutionScale = !isNaN(resolutionScale) ? resolutionScale : 1.0;
        resolutionScale = CesiumMath.clamp(resolutionScale, 0.1, 2.0);
        viewer.resolutionScale = resolutionScale;
    }, [projectStore.renderResolutionValue])

    useEffect(() => {
        if (!viewerRef.current) return
        if (!viewerRef.current.cesiumElement) return
        const viewer = viewerRef.current.cesiumElement
        viewer.useBrowserRecommendedResolution = projectStore.browserRecommendedResolution

    }, [projectStore.browserRecommendedResolution])

    useEffect(() => {
        if (projectStore.modelLists?.length > 0) {
            const temp = projectStore.modelLists.map((element, index) => {
                return {
                    isVisible: true,
                    id: element.id,
                    type: element.type,
                    ionAccess: element?.ionAccess,
                    title: element.title
                }
            })
            projectStore.setVisibleModels(temp)
            const fetchModel = async () => {
                projectStore.setLoadingProgress(true)
                await Promise.all(projectStore.modelLists.map(async element => {
                    try {
                        const elm = await fetchData(element)
                        return elm
                    }
                    catch {
                        return {
                            error: "notfoundresource"
                        }
                    }
                }))
                    .then(res => {
                        projectStore.setLoadingProgress(false);
                        projectStore.setListModelView(res ? res.filter(c => c.error !== 'notfoundresource') : [])
                    }).catch(err => {
                        console.log(err)
                        projectStore.setLoadingProgress(false);
                    })
            }
            fetchModel();

        }
    }, [projectStore.modelLists])

    const getPins = (category) => {
        const stack = []
        const subs = []
        category.map(item => stack.push(item));
        while (stack.length > 0) {
            const node = stack.shift();
            if (node && node.type && node.pin) {
                subs.push(toJS(node))
            }
            if (node?.subcategories) {
                node.subcategories.map(child => stack.push(child))
            }
            if (node?.subcategorychecklists) {
                node.subcategorychecklists.map(child => stack.push(child))
            }
        }
        return subs
    }

    const drawPin = (pins) => {
        const viewer = viewerRef?.current?.cesiumElement
        if (!viewer) return
        if (pins && pins.length > 0) {
            pins.map(element => {
                const pin = element.pin
                if (pin && pin.length > 0) {
                    pin.map(p => {
                        if (p.lat && p.long) {
                            let pinTileset = viewer.entities.add({
                                position: Cartesian3.fromDegrees(p.long, p.lat, p.height || 100),
                                billboard: {
                                    image: p?.icon || pinLocationIcon,
                                    width: p?.size?.width || 64,
                                    height: p?.size?.height || 64,
                                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                                },
                                label: {
                                    text: p?.title,
                                    font: "bold 14pt monospace",
                                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                                    outlineWidth: 2,
                                    verticalOrigin: Cesium.VerticalOrigin.TOP,
                                    pixelOffset: new Cesium.Cartesian2(0, -85),
                                }
                            });
                            pinTileset.key = `${element.id}-pinLocation-${p.id}-${element.subCategoryId ? "sub2Folder" : "subFolder"}`;
                            pinTileset.show = false;
                            pinTileset.propertiesPin = toJS(p)
                            projectStore.addPinModelViews(pinTileset)
                        }
                    })
                }
            })
        }
    }

    const drawNoAccessPin = (projectDetail) => {
        const viewer = viewerRef?.current?.cesiumElement
        if (!viewer) return
        let pins = []
        if (projectDetail?.pin) {
            pins.push(...projectDetail?.pin)
        }
        if (projectDetail?.categories && projectDetail?.categories?.length > 0) {
            for (let i = 0; i < projectDetail?.categories.length; i++) {
                const category = projectDetail?.categories[i];
                if (category?.pin && category?.pin.length > 0) {
                    const pin = category?.pin;
                    pin.map(p => {
                        if (p.lat && p.long) {
                            let pinTileset = viewer.entities.add({
                                position: Cartesian3.fromDegrees(p.long, p.lat, p.height || 100),
                                billboard: {
                                    image: p?.icon || pinLocationIcon,
                                    width: p?.size?.width || 64,
                                    height: p?.size?.height || 64,
                                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                                },
                                label: {
                                    text: p?.title,
                                    font: "bold 14pt monospace",
                                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                                    outlineWidth: 2,
                                    verticalOrigin: Cesium.VerticalOrigin.TOP,
                                    pixelOffset: new Cesium.Cartesian2(0, -85),
                                }
                            });
                            // pinTileset.key = `category-pinLocation-${category.id}-${p.id}`;
                            pinTileset.key = `${category.id}-pinLocation-${p.id}-category`;
                            pinTileset.show = false;
                            pinTileset.propertiesPin = toJS(p)
                            projectStore.addPinModelViews(pinTileset)
                        }
                    })
                }
            }
        }
        if (pins && pins.length > 0) {
            pins.map(element => {
                const pin = element
                if (pin.lat && pin.long) {
                    let pinTileset = viewer.entities.add({
                        position: Cartesian3.fromDegrees(pin.long, pin.lat, pin.height || 100),
                        billboard: {
                            image: pin?.icon || pinLocationIcon,
                            width: pin?.size?.width || 64,
                            height: pin?.size?.height || 64,
                            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        },
                        label: {
                            text: pin?.title,
                            font: "bold 14pt monospace",
                            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                            outlineWidth: 2,
                            verticalOrigin: Cesium.VerticalOrigin.TOP,
                            pixelOffset: new Cesium.Cartesian2(0, -85),
                        },
                    });
                    pinTileset.key = `${element.id}-no-control-access`;
                    pinTileset.show = true;
                    pinTileset.propertiesPin = toJS(pin)
                }
            })
        }
    }
    const drawTerrainProject = async (ionTerrain) => {
        const viewer = viewerRef.current.cesiumElement
        if (!viewer || !ionTerrain?.ionAccess) return
        if (ionTerrain.type === 'TERRAIN') {
            viewer.scene.setTerrain(
                new Cesium.Terrain(Cesium.CesiumTerrainProvider.fromIonAssetId(ionTerrain.ionAccess))
            );
        } else {
            await viewer.imageryLayers.addImageryProvider(
                await Cesium.IonImageryProvider.fromAssetId(ionTerrain.ionAccess)
            );
        }
    }

    const fetchData = async (element, external, isModelFolder) => {
        const viewer = viewerRef.current.cesiumElement
        if (!viewer) return
        var tileset
        if (element.type === 'ion') {
            tileset = await Cesium.Cesium3DTileset.fromIonAssetId(
                element.ionAccess, {
                dynamicScreenSpaceError: true,
                dynamicScreenSpaceErrorDensity: 0.00278,
                dynamicScreenSpaceErrorFactor: 4.0,
                dynamicScreenSpaceErrorHeightFalloff: 0.25
            });
            tileset.key = element.id;
            tileset.modelType = 'ion';
            tileset.show = external ? true : false;
            // const oldKey = projectStore.selectedMenus.filter(c => c !== element.id)
            // if (projectStore.selectedMenus.includes(element.id)) {
            //     projectStore.setSelectedMenus([...oldKey, element.id])
            // } else {
            //     projectStore.setSelectedMenus([...projectStore.selectedMenus, element.id])
            // }

            if (viewer?.scene?.primitives?._primitives.length > 0) {
                const _primitives = viewer?.scene?.primitives?._primitives
                let nodeModel = _primitives.find(c => c.key === element.id)
                if (!nodeModel) {
                    viewer.scene.primitives.add(tileset);
                }
            } else {
                viewer.scene.primitives.add(tileset);
            }
        }
        if (element.type === 'geojson') {
            await IonResource.fromAssetId(element.ionAccess).then(async function (resource) {
                if (viewer?.dataSources) {
                    var geojson = await resource.fetchJson();
                    var extent = geojsonBbox(geojson);

                    const entityCollection = await Cesium.GeoJsonDataSource.load(geojson)
                    if (element.height) {
                        for (let index = 0; index < entityCollection.entities.values.length; index++) {
                            const entity = entityCollection.entities.values[index];
                            if (entity.polygon) entity.polygon.height = element.height
                            if (entity.polyline) entity.polyline.height = element.height
                        }
                    }
                    tileset = await viewer.dataSources.add(entityCollection);
                    tileset.key = element.id;
                    tileset.modelType = 'geojson';
                    tileset.show = external ? true : false;
                    tileset.extent = extent;
                    tileset.clampToGround = false;
                    if (element.stroke || element.fill) {
                        for (let index = 0; index < entityCollection.entities.values.length; index++) {
                            const entity = entityCollection.entities.values[index];
                            let _strokeData = element.stroke
                            let _fillData = element.fill
                            if (entity.polygon) {
                                // Set the fill color with opacity
                                entity.polygon.material = new Cesium.ColorMaterialProperty(
                                    Cesium.Color.fromCssColorString(_fillData?.color || '#ff0000').withAlpha(_fillData?.opacity || 0.5)
                                )
                            }
                            if (entity.polyline) {
                                entity.polyline.width = _strokeData?.width || 3 // Set the stroke width
                                entity.polyline.material = new Cesium.PolylineDashMaterialProperty({
                                    color: Cesium.Color.fromCssColorString(_strokeData?.color || '#00ff00').withAlpha(_strokeData?.opacity || 0.5),
                                    dashLength: 16
                                }) // Set the stroke color, stroke opacity and a dash pattern
                            }
                        }
                    }
                    flyHome(tileset)
                    if (tileset.entities && tileset.entities.values && tileset.entities.values.length > 0) {
                        tileset.entities.values[0].key = element.id;
                    }
                }
            });
        }
        if (element.type === 'czml') {
            await IonResource.fromAssetId(element.ionAccess).then(async function (resource) {
                if (viewer?.dataSources) {
                    const entityCollection = await Cesium.CzmlDataSource.load(resource)
                    tileset = await viewer.dataSources.add(entityCollection);
                    tileset.key = element.id;
                    tileset.modelType = 'czml';
                    tileset.show = false;
                }
            });
        }
        if (element.type === 'imagery') {
            tileset = await viewer.imageryLayers.addImageryProvider(
                await Cesium.IonImageryProvider.fromAssetId(element.ionAccess)
            );
            tileset.key = element.id;
            tileset.modelType = 'imagery';
            tileset.show = external ? true : false;
        }
        if (isModelFolder) {
            tileset.key = `${element.id}-modelLocation${element.categoryId ? `-${element.categoryId}-folder` : ''}${element.subCategoryId ? `-${element.subCategoryId}-subFolder` : ''}${element.subCategoryChecklistId ? `-${element.subCategoryChecklistId}-sub2Folder` : ''}`;
            projectStore.addModelViews(tileset)
        }
        return tileset
    }
    useEffect(() => {
        if (!viewerRef.current || !viewerRef.current?.cesiumElement) return
        var handlerMeasure = new ScreenSpaceEventHandler(
            viewerRef.current.cesiumElement.canvas
        )
        if (projectStore.viewMode === 'Measure distance') {
            setMeasureDistance(
                <MeasureDistance
                    viewer={viewerRef}
                    handler={handlerMeasure}
                />
            )
        }
        return () => {
            if (handlerMeasure) handlerMeasure.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
            setMeasureDistance(false)
        }
    }, [projectStore.viewMode])

    useEffect(() => {
        if (!viewerRef.current || !viewerRef.current.cesiumElement) return
        var handlerFly = new ScreenSpaceEventHandler(
            viewerRef.current.cesiumElement.canvas
        )
        if (projectStore.flyWalkMode) {
            setFlyWalk(
                <FlyWalk
                    viewer={viewerRef}
                    handler={handlerFly}
                />
            )
        }
        return () => {
            if (handlerFly) handlerFly.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
            setFlyWalk(false)
        }

    }, [projectStore.flyWalkMode])

    useEffect(() => {
        if (!viewerRef.current || !viewerRef.current.cesiumElement) return
        if (projectStore.currentToolControl === 'bloom') {
            setBloomControl(
                <BloomControl
                    scene={viewerRef.current.cesiumElement.scene}
                    viewer={viewerRef.current.cesiumElement}
                    globe={viewerRef.current.cesiumElement.scene.globe}
                    canvas={viewerRef.current.cesiumElement.canvas}
                    skyAtmosphere={viewerRef.current.cesiumElement.scene.skyAtmosphere}
                />
            )
        } else {
            setBloomControl(false)
        }
        if (projectStore.currentToolControl === 'skybox') {
            setSkyBoxControl(
                <SkyBoxControl
                    viewer={viewerRef.current.cesiumElement}
                    scene={viewerRef.current.cesiumElement.scene}
                />
            )
        } else {
            setSkyBoxControl(false)
        }

        if (projectStore.currentToolControl === 'fog') {
            setFogControl(
                <FogControl
                    fog={viewerRef.current.cesiumElement.scene?.fog}
                />
            )
        } else {
            setFogControl(false)
        }

        if (projectStore.currentToolControl === 'enhancement') {
            setEnhancementControl(
                <EnhancementEchniquesControl
                    scene={viewerRef.current.cesiumElement.scene}
                />
            )
        } else {
            setEnhancementControl(false)
        }

    }, [projectStore.currentToolControl])

    const getCurrentCamera = viewerRef => {
        if (viewerRef.current != null) {
            let camera = viewerRef.current.cesiumElement.camera
            let camData = {}
            camData.position = {
                x: camera.position.x,
                y: camera.position.y,
                z: camera.position.z,
            }
            camData.direction = {
                x: camera.direction.x,
                y: camera.direction.y,
                z: camera.direction.z,
            }
            camData.up = {
                x: camera.up.x,
                y: camera.up.y,
                z: camera.up.z,
            }
            camData.orientation = {
                heading: camera.heading,
                pitch: camera.pitch,
                roll: camera.roll
            }
            return camData
        }
        return false
    }
    useEffect(() => {
        if (!viewerRef.current || !viewerRef.current.cesiumElement) return;

        setFogView(false);
        const maxHeight = projectStore.cameraPosition?.height || 0
        if (projectStore.viewModelFog?.show && maxHeight < 20000) {
            const fragmentShaderSource = `
            float getDistance(sampler2D depthTexture, vec2 texCoords) {
                float depth = czm_unpackDepth(texture2D(depthTexture, texCoords));
                if (depth == 0.0) {
                    return czm_infinity;
                }
                vec4 eyeCoordinate = czm_windowToEyeCoordinates(gl_FragCoord.xy, depth);
                return -eyeCoordinate.z / eyeCoordinate.w;
            }
            
            float interpolateByDistance(vec4 nearFarScalar, float distance) {
                float startDistance = nearFarScalar.x;
                float startValue = nearFarScalar.y;
                float endDistance = nearFarScalar.z;
                float endValue = nearFarScalar.w;
                float t = clamp((distance - startDistance) / (endDistance - startDistance), 0.0, 1.0);
                return mix(startValue, endValue, t);
            }
            
            vec4 alphaBlend(vec4 sourceColor, vec4 destinationColor) {
                return sourceColor * vec4(sourceColor.aaa, 1.0) + destinationColor * (1.0 - sourceColor.a);
            }
            
            uniform sampler2D colorTexture;
            uniform sampler2D depthTexture;
            uniform vec4 fogByDistance;
            uniform vec4 fogColor;
            varying vec2 v_textureCoordinates;
            
            void main(void) {
                float distance = getDistance(depthTexture, v_textureCoordinates);
                vec4 sceneColor = texture2D(colorTexture, v_textureCoordinates);
                float blendAmount = interpolateByDistance(fogByDistance, distance);
                vec4 finalFogColor = vec4(fogColor.rgb, fogColor.a * blendAmount);
                gl_FragColor = alphaBlend(finalFogColor, sceneColor);
            }
            
            `;

            setFogView(
                <PostProcessStage
                    fragmentShader={fragmentShaderSource}
                    uniforms={{
                        fogByDistance: Cartesian4.fromArray([projectStore.viewModelFog.fogByDistanceX, 0.0, projectStore.viewModelFog.fogByDistanceY, 1.0]),
                        fogColor: Color.fromCssColorString(projectStore.viewModelFog.color).withAlpha(projectStore.viewModelFog.alpha)
                    }}
                />
            );

            if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement.scene) {
                let _cameraLocation = getCurrentCamera(viewerRef);

                if (_cameraLocation.position) {
                    let flyOption = {
                        orientation: {
                            direction: _cameraLocation.direction,
                            up: _cameraLocation.up,
                        },
                        destination: new Cartesian3(
                            _cameraLocation.position.x + 0.00001, // 0.00001 for effect refresh viewer
                            _cameraLocation.position.y,
                            _cameraLocation.position.z
                        ),
                        endTransform: Cesium.Matrix4.IDENTITY
                    };

                    viewerRef.current.cesiumElement.camera.setView(flyOption);
                }
            }
        } else {
            setFogView(false);
        }

        return () => {
            setFogView(false);
        };
    }, [projectStore.viewModelFog.show, projectStore.viewModelFog.fogByDistanceY, projectStore.viewModelFog.fogByDistanceX, projectStore.viewModelFog.color, projectStore.viewModelFog.alpha, projectStore.cameraPosition?.height]);

    useEffect(() => {
        if (!(projectStore.projectDetail)) return
        if (viewerRef && viewerRef.current && viewerRef.current.cesiumElement) {
            viewerRef.current.cesiumElement.shadows = projectStore.enableShadows
        }
        startReRender()
    }, [projectStore.enableShadows, projectStore.isShowShadowModal])

    /** Effect for show time slider */
    useEffect(() => {
        if (!viewerRef.current) return
        if (!viewerRef.current.cesiumElement) return
        if (!viewerRef.current.cesiumElement.clockViewModel) return
        if (!viewerRef.current.cesiumElement.clockViewModel.clock) return
        const viewer = viewerRef.current.cesiumElement;
        if (projectStore.currentViewingTime && projectStore.currentViewingTime.toDate) {
            viewer.clockViewModel.clock.currentTime = JulianDate.fromDate(projectStore.currentViewingTime.toDate());
            viewer.clockViewModel.synchronize();
            startReRender()
        }
    }, [projectStore.currentViewingTim, projectStore.isShowShadowModal])

    const checkLoading = () =>
        !!(authStore.isLoading || projectStore.isLoading || userStore.isLoading);
    const flyHome = (zoomModel) => {
        const node = projectStore.projectDetail.defaultZoom
        if (projectStore.projectDetail?.startView) {
            const lat = projectStore.projectDetail?.startView.lat
            const lon = projectStore.projectDetail?.startView.long
            const height = projectStore.projectDetail?.startView.height
            const heading = projectStore.projectDetail?.startView.heading
            const pitch = projectStore.projectDetail?.startView.pitch
            const roll = projectStore.projectDetail?.startView.roll
            const flyOption = {
                duration: defaultDuration,
                destination: Cartesian3.fromDegrees(lon, lat, height),
                orientation: {
                    heading: heading,
                    pitch: pitch,
                    roll: roll
                },
            };
            const viewer = viewerRef.current.cesiumElement
            viewer.scene.camera.flyTo(flyOption);
        }
        else if (node) {
            const viewer = viewerRef.current.cesiumElement
            if (!zoomModel) {
                zoomModel = projectStore.listModelView?.find(x => x?.key === node);
            }

            if (zoomModel && zoomModel.key && zoomModel.key === node && zoomModel.extent) {

                const rectangle = Cesium.Rectangle.fromDegrees(
                    ...zoomModel.extent
                );
                viewer.camera.flyTo({
                    destination: rectangle,
                    duration: defaultDuration,
                });
            }
        }
    }

    const defaultImagery = useMemo(() => {
        var imageryProviders = createDefaultImageryProviderViewModels();
        var imageryViewModels = [];
        imageryViewModels.push(new ProviderViewModel({
            name: 'Arcgis',
            iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/bingAerialLabels.png'),
            tooltip: 'bing-maps-standard-road-maps-provided-by-cesium-ion',
            category: 'Basemaps',
            show: true,
            creationFunction: async function () {
                return await Cesium.ArcGisMapServerImageryProvider.fromUrl("https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer")
            }
        }));
        imageryProviders.forEach(element => {
            if (element.category === "Cesium ion") {
                //
            } else {
                if (element.name === "Open­Street­Map") {
                    imageryViewModels.push(new ProviderViewModel({
                        name: 'Open­Street­Map',
                        iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
                        tooltip: 'OpenStreetMap (OSM) is a collaborative project to create a free editable map of the world.\nhttp://www.openstreetmap.org',
                        category: 'Basemaps',
                        show: true,
                        creationFunction: function () {
                            return new Cesium.OpenStreetMapImageryProvider()
                        }
                    }));
                }
            }
        });

        return imageryViewModels
    }, [])

    return (
        <ProjectWrapper>
            <Row gutter={[24, 0]}>
                <Viewer
                    full
                    timeline={true}
                    homeButton={true}
                    navigationInstructionsInitiallyVisible={false}
                    navigationHelpButton={false}
                    selectionIndicator={false}
                    infoBox={false}
                    sceneModePicker={false}
                    shouldAnimate={true}
                    ref={viewerRef}
                    shadows={projectStore.enableShadows}
                    imageryProviderViewModels={defaultImagery}
                >
                    {measureDistance}
                    {flyWalk}
                    {skyBoxControl}
                    {bloomControl}
                    {fogControl}
                    {fogView}
                    {enhancementControl}
                </Viewer>
            </Row>
            {projectStore.projectDetail && viewerRef?.current?.cesiumElement &&
                <MainMenu
                    viewerRef={viewerRef}
                    subCategories={subCategories}
                    setSubCategories={setSubCategories}
                />
            }
            {(projectStore.rightData && projectStore.rightData.length > 0) && <RightContainer />}
            {viewerRef?.current?.cesiumElement ? (
                <ToolbarRight flyHome={flyHome} viewer={viewerRef.current.cesiumElement} />
            ) : ('')}
            {viewerRef?.current?.cesiumElement && checkShowWidgets('widget_cameraposition') ? (
                <CameraPosition viewer={viewerRef.current.cesiumElement} />
            ) : ('')}
            {viewerRef?.current?.cesiumElement && projectStore.currentToolControl === 'optimize' ? (
                <OptimizeControl viewer={viewerRef.current.cesiumElement} />
            ) : ('')}
            {viewerRef?.current?.cesiumElement && projectStore.isShowShadowModal ? (
                <ShadowSchedule viewer={viewerRef.current.cesiumElement} />
            ) : ('')}
            {viewerRef?.current?.cesiumElement ? (
                <ModalAtrribute viewer={viewerRef.current.cesiumElement} />
            ) : ('')}
            {checkLoading() ? (
                <LoadingSpinner theme={"appTheme"} type={"page"} />
            ) : null}

            <div id="navigation-portal" />
        </ProjectWrapper>
    );
};
export default inject(
    "authStore",
    "projectStore",
    "userStore"
)(observer(ProjectDetail));