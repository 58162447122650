import { Col, Form, Input, InputNumber, Modal, Checkbox, Select } from "antd";
import { useEffect, useState } from "react";
import GroupItem from "../../AdminDashboard/GroupItem";
import TextArea from "antd/lib/input/TextArea";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import UploadImage from "../../../components/elements/UploadImage/UploadImage";
import PinTable from "../PinTable";
const { Option } = Select;
const ModalAddPin = ({ adminStore, setPinsProject, projectId, setPinsFolder, folderId, setPinsSubFolder, subFolderId, setPinsSubSubFolder, subSubFolderId }) => {
     const [imageUrl, setImageUrl] = useState(null)
     const [checked, setChecked] = useState(true);
     const [allPin, setAllPin] = useState([]);
     const [addPinForm] = Form.useForm();
     const location = useLocation();
     const [dataSource, setDataSource] = useState([]);
     let pathname = location.pathname;

     useEffect(() => {
          const fetchData = async () => {
               await adminStore.getAllPins(1, 1000).then(res => {
                    setAllPin(res.docs)
               })
               addPinForm.setFieldsValue({
                    icon: "https://s3.eu-west-3.amazonaws.com/bacha.zenpix/Icons/icons8-home-address-96.png"
               })
          }
          fetchData()
          return () => {
               addPinForm.resetFields();
               setImageUrl(null);
               setChecked(true);
               setDataSource([])
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [adminStore.openPinModal])

     const cancelAddPin = () => {
          adminStore.setOpenPinModal(false);
          addPinForm.resetFields();
          setChecked(true)
     };
     const onChangeCheckbox = (e) => {
          setChecked(e.target.checked)
     };
     const createPin = async (payload) => {
          adminStore.setLoadingProgress(true);
          await adminStore.createNewPin(payload).then((response) => {
               if (pathname.includes("projects")) {
                    setPinsProject(pins => [...pins, response]);
               }
               if (pathname.includes("/folders/")) {
                    setPinsFolder(pins => [...pins, response])
               }
               if (pathname.includes("/subfolders/")) {
                    setPinsSubFolder(pins => [...pins, response])
               }
               if (pathname.includes("sub-subfolders")) {
                    setPinsSubSubFolder(pins => [...pins, response])
               }
               cancelAddPin();
               adminStore.setLoadingProgress(false);
               adminStore.openNotification('bottomRight', 'success', 'Create new pin successfully!');
          })
               .catch((err) => {
                    adminStore.setLoadingProgress(false);
                    adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
               });
     };
     const handleCreateNewPin = () => {
          addPinForm.validateFields().then(async response => {
               const docId = pathname.includes("projects") ? projectId : pathname.includes("/folders/") ? folderId : pathname.includes("/subfolders/") ? subFolderId : pathname.includes("sub-subfolders") ? subSubFolderId : "";
               const docModel = pathname.includes("projects") ? "Project" : pathname.includes("/folders/") ? "Category" : pathname.includes("/subfolders/") ? "SubCategory" : pathname.includes("sub-subfolders") ? "SubCategoryChecklist" : "";
               if (response.useExisting) {
                    await adminStore.getPinDetail(response.pin).then(async (res) => {
                         let payload = {
                              title: res?.title,
                              docId,
                              docModel,
                              lat: res?.lat || 0,
                              long: res?.long || 0,
                              height: res?.height || 0,
                              icon: res?.icon || '',
                              orientation: {
                                   heading: res?.orientation?.heading || 0,
                                   pitch: res?.orientation?.pitch || 0,
                                   roll: res?.orientation?.roll || 0,
                              },
                              size: {
                                   height: res?.size?.height || 64,
                                   width: res?.size?.width || 64
                              },
                              properties: {
                                   title: res?.properties?.title || "",
                                   description: res?.properties?.description || "",
                                   image: res?.properties?.image || "",
                                   dataSource: res?.properties?.dataSource || [],
                                   status: res?.properties?.status || "",
                              }
                         };
                         await createPin(payload)
                    })
               } else {
                    let payload = {
                         title: response?.title,
                         docId,
                         docModel,
                         lat: response?.lat || 0,
                         long: response?.long || 0,
                         height: response?.height || 0,
                         icon: response?.icon || '',
                         orientation: {
                              heading: response?.heading || 0,
                              pitch: response?.pitch || 0,
                              roll: response?.roll || 0,
                         },
                         size: {
                              height: response?.sizeHeight || 64,
                              width: response?.sizeWidth || 64,
                         },
                         properties: {
                              title: response?.propertiesTitle || "",
                              description: response?.propertiesDescription || "",
                              image: imageUrl || "",
                              dataSource,
                              status: response?.propertiesStatus || "",
                         }
                    };
                    await createPin(payload)
               }
          })
     };

     return (
          <Modal
               title={`Add pin into ${pathname.includes("projects") ? "project" : pathname.includes("/folders/") ? "folder" : pathname.includes("/subfolders/") ? "sub folder" : pathname.includes("sub-subfolders") ? "sub-subfolder" : ""}`}
               zIndex={9999}
               open={adminStore.openPinModal}
               onOk={handleCreateNewPin}
               onCancel={cancelAddPin}
               maskClosable={false}
               className='modal-add-folder'
          >
               <Form
                    name="add-pin-to-project"
                    form={addPinForm}
                    layout="vertical"
                    labelAlign="left"
               >
                    <Form.Item
                         name="useExisting"
                         valuePropName="checked"
                         initialValue={true}
                         rules={[
                              {
                                   required: false,
                              },
                         ]}
                    >
                         <Checkbox checked={checked} onChange={onChangeCheckbox}>Use existing pin</Checkbox>
                    </Form.Item>
                    {
                         checked ? (
                              <Form.Item
                                   label={"Pin"}
                                   name="pin"
                                   rules={[
                                        {
                                             required: true,
                                             message:
                                                  "Please select pin",
                                        },
                                   ]}
                              >
                                   <Select
                                        placeholder={
                                             "Please select pin"
                                        }
                                        showSearch
                                        allowClear={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                             option.children
                                                  .toLowerCase()
                                                  .indexOf(input.toLowerCase()) >= 0
                                        }
                                   >
                                        {
                                             allPin.map((v, i) => {
                                                  return (
                                                       <Option key={v.id} value={v.id}>
                                                            {v.title}
                                                       </Option>
                                                  );
                                             })}
                                   </Select>
                              </Form.Item>
                         ) : <>
                              <Form.Item
                                   name={["title"]}
                                   label="Title"
                                   rules={[
                                        {
                                             required: true,
                                        },
                                   ]}
                              >
                                   <Input
                                        className="form-input"
                                        placeholder="Please input title"
                                   />
                              </Form.Item>
                              <Form.Item name={"icon"} label="Icon"
                                   rules={[
                                        {
                                             required: true,
                                             message: "Please input pin url",
                                        },
                                   ]}
                              >
                                   <Input />
                              </Form.Item>
                              <GroupItem title={"orientation"}>
                                   <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                                        <Form.Item name={"heading"} label="Heading">
                                             <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                   </Col>
                                   <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                                        <Form.Item name={["pitch"]} label="Pitch">
                                             <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                   </Col>
                                   <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                                        <Form.Item name={"roll"} label="Roll">
                                             <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                   </Col>
                              </GroupItem>
                              <Form.Item name={["lat"]} label="Lat" rules={[
                                   {
                                        required: false,
                                        message: "Please input lat",
                                   },
                              ]}>
                                   <Input
                                        type="number"
                                        className="form-input"
                                        placeholder="Please input lat"
                                   />
                              </Form.Item>
                              <Form.Item name={["long"]} label="Long" rules={[
                                   {
                                        required: false,
                                        message: "Please input long",
                                   },
                              ]}>
                                   <Input
                                        type="number"
                                        className="form-input"
                                        placeholder="Please input long"
                                   />
                              </Form.Item>
                              <Form.Item name={["height"]} label="Height" rules={[
                                   {
                                        required: false,
                                        message: "Please input height",
                                   },
                              ]}>
                                   <Input
                                        type="number"
                                        className="form-input"
                                        placeholder="Please input height"
                                   />
                              </Form.Item>
                              <GroupItem title={"size"}>
                                   <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name={"sizeHeight"} label="Height">
                                             <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                   </Col>
                                   <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name={"sizeWidth"} label="Width">
                                             <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                   </Col>
                              </GroupItem>
                              <GroupItem title={"properties"}>
                                   <Col span={24}>
                                        <Form.Item
                                             name={["propertiesTitle"]}
                                             label="Title"
                                        >
                                             <Input
                                                  className="form-input"
                                                  placeholder="Please input properties title"
                                             />
                                        </Form.Item>
                                   </Col>
                                   <Col span={24}>
                                        <Form.Item
                                             name={["propertiesStatus"]}
                                             label="status"
                                        >
                                             <Input
                                                  className="form-input"
                                                  placeholder="Please input properties status"
                                             />
                                        </Form.Item>
                                   </Col>
                                   <Col span={24}>
                                        <Form.Item
                                             name={["propertiesDescription"]}
                                             label="Description"
                                        >
                                             <TextArea rows={4} className="form-input" placeholder="Please input properties description" />
                                        </Form.Item>
                                   </Col>
                                   <Col span={24}>
                                        <PinTable setDataSource={setDataSource} dataSource={dataSource} ></PinTable>
                                   </Col>
                                   <Col span={24}>
                                        <UploadImage imageUrl={imageUrl} setImageUrl={setImageUrl} propertiesName={'propertiesImage'} />
                                   </Col>
                              </GroupItem>
                         </>
                    }

               </Form>
          </Modal>
     )
}

export default inject("userStore", "adminStore", "authStore")(observer(ModalAddPin));