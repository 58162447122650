import { Checkbox, Col, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import {
    CloseOutlined,
} from "@ant-design/icons";
import {
    MainMenuItemWrapper,
    SubMenuWrapper,
    SubMenuItemWrapper,
} from "./styled";
import { Cartesian3, Math as CesiumMath } from "cesium";
import { hexToRgba } from "../../../utils/projectLib";
const Cesium = require('cesium')
const defaultDuration = 4;
const RenderSubSubFolder = ({ projectStore, viewerRef, setShowSubMenu, isShowSubMenu, element, maxHeight, setActiveMenu }) => {

    const searchElement = (data, key, match) => {
        const stack = [];
        data.map(item => stack.push(item));
        while (stack.length > 0) {
            const node = stack.pop();
            if (node[key] === match) {
                return node;
            } else if (node.subcategories) {
                node.subcategories.map(child => stack.push(child))
            } else if (node.subcategorychecklists) {
                node.subcategorychecklists.map(child => stack.push(child))
            }
        }
        return null;
    }

    const handleClickPlaceSubMenu = (element, stt) => {
        if (!viewerRef.current) return
        if (!viewerRef.current.cesiumElement) return
        if (viewerRef.current.cesiumElement) {
            if (!element) return
            const { orientation } = element;
            const flyOption = {
                duration: defaultDuration,
                destination: Cartesian3.fromDegrees(orientation.lon || 0, orientation.lat || 0, orientation?.height || 100),
                orientation: {
                    heading: orientation?.heading || CesiumMath.toRadians(20),
                    pitch: orientation?.pitch || CesiumMath.toRadians(-20),
                    roll: orientation?.roll || CesiumMath.toRadians(-20)
                },
            };
            const viewer = viewerRef.current.cesiumElement
            viewer.scene.camera.flyTo(flyOption);
        }
        const reset = () => {
            projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element?.id));
        }
        if (!stt) {
            setTimeout(reset, 100)
        }
    }

    const handleClickSubMenu = (key, stt) => {
        const node = searchElement(element?.subcategorychecklists, "id", key);
        if (node) {
            if (node.type === "place") {
                handleClickPlaceSubMenu(node);
            }
            else {
                let zoomModel
                projectStore.listModelView.map(item => {
                    if (item.key === key) {
                        zoomModel = item;
                        if (stt) zoomModel.show = true;
                    }
                })
                let orientation;
                let destination;
                if (node.orientation) {
                    if ((node.orientation?.heading || node.orientation?.heading === 0) && (node.orientation?.pitch || node.orientation?.pitch === 0)) {
                        orientation = {
                            heading: node.orientation.heading,
                            pitch: node.orientation.pitch,
                            roll: node.orientation.roll || 0
                        }
                    }
                    if (node.orientation?.lat && node.orientation?.lon) {
                        destination = Cesium.Cartesian3.fromDegrees(node.orientation?.lon, node.orientation?.lat, node.orientation.height || 100)
                    }
                }
                if (zoomModel) {
                    const viewer = viewerRef.current.cesiumElement
                    if (zoomModel.modelType === 'geojson' && zoomModel.extent) {
                        const rectangle = Cesium.Rectangle.fromDegrees(
                            ...zoomModel.extent
                        );
                        const flyOption = {
                            destination: rectangle,
                            duration: defaultDuration,
                        }
                        if (orientation) {
                            flyOption.orientation = orientation;
                        }
                        if (destination) {
                            flyOption.destination = destination;
                        }
                        viewer.camera.flyTo(flyOption);

                    } else {
                        const zoomOption = { duration: defaultDuration }
                        if (zoomModel.modelType === "ion") {
                            zoomOption.offset = new Cesium.HeadingPitchRange(
                                0.0,
                                -0.5,
                                zoomModel.boundingSphere.radius * 4.0
                            )
                        }
                        if (orientation) {
                            if (zoomModel?.boundingSphere) {
                                const cartographic = Cesium.Cartographic.fromCartesian(
                                    zoomModel.boundingSphere.center
                                );
                                const flyOption = {
                                    duration: defaultDuration,
                                    destination: Cartesian3.fromDegrees(
                                        Cesium.Math.toDegrees(cartographic.longitude),
                                        Cesium.Math.toDegrees(cartographic.latitude),
                                        cartographic?.height || 100
                                    ),
                                    orientation
                                };

                                if (destination) {
                                    flyOption.destination = destination;
                                }
                                viewer.scene.camera.flyTo(flyOption);
                            } else if ((node?.orientation?.lon || node?.orientation?.lon === 0) && (node?.orientation?.lat || node?.orientation?.lat === 0)) {
                                const flyOption = {
                                    duration: defaultDuration,
                                    destination: Cartesian3.fromDegrees(node.orientation.lon, node.orientation.lat, node?.orientation?.height || 100),
                                    orientation: {
                                        heading: orientation.heading || CesiumMath.toRadians(20),
                                        pitch: orientation.pitch || CesiumMath.toRadians(-20),
                                        roll: orientation.roll || CesiumMath.toRadians(-20)
                                    },
                                };
                                viewer.scene.camera.flyTo(flyOption);
                            }
                        } else {
                            viewer.flyTo(zoomModel, zoomOption);
                        }
                        const reset = () => {
                            projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== node?.id));
                            zoomModel.show = false
                        }
                        if (!stt) {
                            setTimeout(reset, 100);
                        }
                    }
                }
            }
        }
    }


    const setTop = () => {
        let topValue = 0;
        for (let i = 0; i < projectStore.activeElement?.subcategories?.length; i++) {
            if (projectStore?.activeElementItem === projectStore.activeElement?.subcategories[i]?.id) {
                topValue = window.innerHeight - (window.innerHeight - (30 + i * 30));
            }
        }
        return 0;
        return topValue;
    }
    const setHeight = () => {
        let max = window.innerHeight - setTop() - ((element?.subcategorychecklists.length + 2) * 60);
        return max;
    }
    return (
        <>
            <SubMenuWrapper style={setHeight() > 0 ? { top: setTop() } : {}} colormenu={projectStore.projectDetail.colormenu}>
                <Col className="close-btn" span={24}>
                    <Tooltip title='Close' placement="right">
                        <span onClick={() => {
                            setShowSubMenu(!isShowSubMenu)
                            setActiveMenu(false)
                        }}>
                            <CloseOutlined />
                        </span>
                    </Tooltip>
                </Col>
                <SubMenuItemWrapper maxHeight={setHeight() > 0 ? setHeight() : 300} colormenu={projectStore.projectDetail.colormenu}>
                    {
                        element?.subcategorychecklists
                            .sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)
                            .map((sub, subIndex) => {
                                return (
                                    <MainMenuItemWrapper
                                        colormenu={projectStore.projectDetail.colormenu}
                                        colormenurgba={hexToRgba(projectStore.projectDetail.colormenu, 0.7)}
                                        justify='space-between'
                                        key={`${sub.title}-${subIndex}`}
                                        onClick={() => {

                                        }}
                                    >
                                        <Col onClick={() => {
                                            handleClickSubMenu(sub.id, projectStore.selectedMenus.includes(sub.id))
                                        }
                                        } span={20} >{sub.title}</Col>
                                        <Col>
                                            {(sub?.pin?.length > 0 || sub?.model3ds?.length > 0) && (<Checkbox
                                                checked={projectStore.selectedMenus.includes(sub.id)}
                                                onChange={(e) => {
                                                    const pinKey = sub.id + '-pinLocation';
                                                    let selectedMenus = projectStore.selectedMenus
                                                    if (e.target.checked) {
                                                        projectStore.setSelectedMenus([...selectedMenus, sub.id])
                                                    } else {
                                                        projectStore.setSelectedMenus(selectedMenus.filter(x => x !== sub?.id));
                                                    }
                                                    let pinModel = projectStore.listPinModelViews.filter(c => c.key.includes(pinKey))
                                                    if (projectStore.listModelViews?.length > 0) {
                                                        let nodeModelsShow = projectStore.listModelViews.filter(c => c?.key?.includes(sub.id))
                                                        if (nodeModelsShow?.length > 0) {
                                                            nodeModelsShow.map(model => {
                                                                model.show = e.target.checked
                                                            })
                                                        }
                                                    }
                                                    if (pinModel.length > 0) {
                                                        pinModel.map(pinData => {
                                                            pinData.show = e.target.checked
                                                            if (pinData?.entityCollection?.values) {
                                                                pinData?.entityCollection?.values.map(c => {
                                                                    if (c?.key?.includes(pinKey)) {
                                                                        c.billboard.show = e.target.checked
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    }
                                                }}
                                            />)
                                            }
                                        </Col>
                                    </MainMenuItemWrapper>
                                )
                            })
                    }
                </SubMenuItemWrapper>
            </SubMenuWrapper>
        </>
    )
}
export default inject("projectStore")(observer(RenderSubSubFolder));