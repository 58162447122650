import { makeAutoObservable } from 'mobx';
import { ProjectRequests, ProjectOldRequest } from '../utils/requests';
import moment from 'moment'

class ProjectStore {
    constructor() {
        makeAutoObservable(this);
    }
    isLoading = false
    activeLabel = 'Contexte Urbain'
    activeElement = {}
    prevElement = {}
    projectDetail = {}
    activeElementItem = false
    categories = []
    checkedElement = false
    modelLists = []
    visibleModels = []
    viewMode = 'Default mode'
    pickedObject = {}
    polylines = []
    listModelView = []
    flyWalkMode = false
    enableShadows = false
    viewCameraFly = false
    browserRecommendedResolution = true
    renderResolutionValue = 1
    showModalAtrribute = false
    cameraPosition = {}
    clickPosition = {}
    showMainMenu = false
    selectedMenus = []
    rightData = []
    lookFactorFlyWalk = 0.5
    isShowShadowModal = false
    currentViewingTime = moment(new Date())
    volautoMode = false
    visitemanuelle = false
    listPinModelViews = []
    widgetsControl = {
        widget_czml: true,
        widget_measure: true,
        widget_optimize: true,
        widget_shadow: true,
        widget_walk: true,
        widget_skybox: true,
        widget_bloomeffect: true,
        widget_fog: true,
        widget_sunshow: true,
        widget_fxaa: true,
        widget_hdr: true,
        widget_cameraposition: true,
    }
    czml = {
        url: 'MultipartVehicle_part.czml',
        path: 'https://modelszenata.s3.eu-west-3.amazonaws.com'
    }

    currSkyBox = 'flashlight'
    viewModelBloom = {
        enableTerrain: false,
        enableLighting: false,
        groundTranslucency: false,
        showGroundAtmosphere: true,
        groundAtmosphereLightIntensity: 10,
        groundAtmosphereRayleighCoefficientR: 5.5,
        groundAtmosphereRayleighCoefficientG: 13,
        groundAtmosphereRayleighCoefficientB: 28.400000000000002,
        groundAtmosphereMieCoefficient: 21,
        groundAtmosphereRayleighScaleHeight: 10000,
        groundAtmosphereMieScaleHeight: 3200,
        groundAtmosphereMieAnisotropy: 0.9,
        groundHueShift: 0,
        groundSaturationShift: 0,
        groundBrightnessShift: 0,
        lightingFadeOutDistance: 10000000,
        lightingFadeInDistance: 20000000,
        nightFadeOutDistance: 10000000,
        nightFadeInDistance: 50000000,
        showSkyAtmosphere: true,
        skyAtmosphereLightIntensity: 50,
        skyAtmosphereRayleighCoefficientR: 5.5,
        skyAtmosphereRayleighCoefficientG: 13,
        skyAtmosphereRayleighCoefficientB: 28.400000000000002,
        skyAtmosphereMieCoefficient: 21,
        skyAtmosphereRayleighScaleHeight: 10000,
        skyAtmosphereMieScaleHeight: 3200,
        skyAtmosphereMieAnisotropy: 0.9,
        skyHueShift: 0,
        skySaturationShift: 0,
        skyBrightnessShift: 0,
        perFragmentAtmosphere: false,
        dynamicLighting: true,
        dynamicLightingFromSun: false,
        showFog: true,
        density: 1,
        minimumBrightness: 0.03,
        hdr: false
    }
    viewModelFog = {
        show: false,
        alpha: 0.6,
        color: '#000000',
        fogByDistanceX: 100,
        fogByDistanceY: 9000,
        rgba: {
            r: '0',
            g: '0',
            b: '0',
            a: '0.6',
        }
    };
    viewModelEnhancementEchniques = {
        hdr: true,
        sun: false,
        fxaa: true
    }
    currentToolControl = ''
    listModelViews = []
    setViewModelEnhancementEchniques = (key, value) => {
        if (this.viewModelEnhancementEchniques) {
            this.viewModelEnhancementEchniques[key] = value
        }
    }
    setCurrentToolControl = (data) => {
        this.currentToolControl = data
    }
    setViewModelFog = (key, value) => {
        if (this.viewModelFog) {
            this.viewModelFog[key] = value
        }
    }

    setViewModelBloom = (key, value) => {
        if (this.viewModelBloom) {
            this.viewModelBloom[key] = value
        }
    }


    setCurrSkyBox = (data) => {
        this.currSkyBox = data
    }

    setCZML = (path, url) => {
        if (path) {
            this.czml = {
                ...this.czml,
                path
            }
        }
        if (url) {
            this.czml = {
                ...this.czml,
                url
            }
        }
        if (!path & !url) {
            this.czml = {
                url: 'MultipartVehicle_part.czml',
                path: 'https://modelszenata.s3.eu-west-3.amazonaws.com'
            }
        }
    }

    addPinModelViews = e => {
        this.listPinModelViews = [...this.listPinModelViews, e]
    }

    addModelViews = e => {
        this.listModelViews = [...this.listModelViews, e]
    }

    setWidgetsControl = objectControl => {
        this.widgetsControl = objectControl
    }

    resetWidgetsControl = () => {
        this.widgetsControl = {
            widget_czml: true,
            widget_measure: true,
            widget_optimize: true,
            widget_shadow: true,
            widget_walk: true,
            widget_skybox: true,
            widget_bloomeffect: true,
            widget_fog: true,
            widget_sunshow: true,
            widget_fxaa: true,
            widget_hdr: true,
            widget_cameraposition: true,
        }
    }

    setvisitemanuelle = e => {
        this.visitemanuelle = e
    }

    setVolautoMode = stt => {
        this.volautoMode = stt
    }

    setAssignVolautoMode = (key, value) => {
        if (this.volautoMode) {
            this.volautoMode[key] = value
        }
    }

    assignVolautoMode = (data) => {
        if (!this.volautoMode) {
            this.volautoMode = {}
        }
        Object.assign(this.volautoMode, data)
    }

    setLookFactorFlyWalk = data => {
        this.lookFactorFlyWalk = data
    }

    setCurrentViewingTime = stt => {
        this.currentViewingTime = stt
    }

    setShowShadowModal = stt => {
        this.isShowShadowModal = stt
    }

    setRightData = data => {
        this.rightData = data
    }

    setSelectedMenus = data => {
        this.selectedMenus = data
    }

    setShowMainMenu = data => {
        this.showMainMenu = data
    }

    setClickPosition = data => {
        this.clickPosition = data
    }

    setCameraPosition = data => {
        this.cameraPosition = data
    }

    setCheckedElement = data => {
        this.checkedElement = data
    }

    setBrowserRecommendedResolution = data => {
        this.browserRecommendedResolution = data
    }

    setShowModalAtrribute = data => {
        this.showModalAtrribute = data
    }

    setResolutionValue = data => {
        this.renderResolutionValue = data
    }

    setViewCameraFly = data => {
        this.viewCameraFly = data
    }
    setFlyWalkMode = status => {
        this.flyWalkMode = status
    }

    setListModelView = (data) => {
        this.listModelView = data
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading
    }
    setProjectDetail = data => {
        this.projectDetail = data
    }

    setCategories = data => {
        this.categories = data
    }

    setActiveLabel = data => {
        this.activeLabel = data
    }
    setActiveElement = data => {
        if (this.activeElement) {
            this.setprevElement(this.activeElement)
        }
        this.activeElement = data
    }
    setprevElement = data => {
        this.prevElement = data
    }
    setActiveElementItem = data => {
        this.activeElementItem = data
    }
    setModelLists = data => {
        this.modelLists = data
    }
    setVisibleModels = data => {
        this.visibleModels = data
    }
    setViewMode = mode => {
        this.viewMode = mode
    }

    getPickedObject(position) {
        if (this.pickedObject.position && this.pickedObject.object) {
            if (this.comparePosition(this.pickedObject.position, position)) {
                return this.pickedObject.object
            }
        }
    }
    comparePosition(positionA, positionB) {
        return positionA.x === positionB.x && positionA.y === positionB.y
    }
    setpickedObject = (object, position) => {
        if (position && object) {
            this.pickedObject = {
                position: {
                    x: position.x,
                    y: position.y
                }, object: object
            }
        } else {
            this.pickedObject = {}
        }
    }

    setPolylines = data => {
        this.polylines = data
    }

    setEnableShadows = mode => {
        this.enableShadows = mode
    }

    getAllProjects = () => {
        this.setLoadingProgress(true)
        return new Promise((resolve, reject) => {
            ProjectOldRequest.getAllProjects()
                .then(response => {
                    this.setProjectDetail(response.data?.docs[0])
                    this.setLoadingProgress(false)
                    resolve(response.data?.docs[0])
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject()
                })
        })
    }

    getProjectDetail = async (projectId) => {
        return new Promise((resolve, reject) => {
            ProjectRequests.getProjectById(projectId)
                .then(response => {
                    this.setProjectDetail(response.data)
                    this.setCategories(response.data?.categories?.sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order) || [])
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    getProjectDefaultDetail = async () => {
        return new Promise((resolve, reject) => {
            ProjectRequests.getProjectDefault()
                .then(response => {
                    this.setProjectDetail(response.data)
                    this.setCategories(response.data?.categories?.sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order) || [])
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }
}
export default ProjectStore;