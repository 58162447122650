import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SaveOutlined
} from "@ant-design/icons";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select
} from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "../../AdminDashboard/AdminDashboard";
import GroupItem from "../../AdminDashboard/GroupItem";
import { ContentWrapper, ToolbarButton, ToolbarWrapper, TableContain, MainContain } from "../../AdminDashboard/style";
import UploadImage from "../../../components/elements/UploadImage/UploadImage";
import { isImage } from "../../../utils/projectLib";
import PinTable from "../PinTable";

const { TextArea } = Input;

const EditPin = ({ adminStore, authStore }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { pinId } = useParams();
  const [subCategorys, setSubCategory] = useState([]);
  const [projects, setProjects] = useState([]);
  const [folders, setFolders] = useState([]);
  const [subSubFolders, setSubSubFolders] = useState([]);
  const [docModelField, setDocModelField] = useState("");
  const [imageUrl, setImageUrl] = useState(null)
  const [dataSource, setDataSource] = useState([]);

  window.onscroll = () =>
    adminStore.onscrollSticky(window, document, "toolbar-sticky");
  const { Option } = Select;

  const page = adminStore.currentPage;
  const limit = adminStore.currentLimit;
  const search = adminStore.searchInput;

  const backTo = () => {
    return navigate(`/admin/pins?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`);
  }
  const updateFormData = (response) => {
    setImageUrl(isImage(response?.properties?.image) && response?.properties?.image || null)
    form.setFieldsValue({
      title: response?.title,
      lat: response?.lat || 0,
      long: response?.long || 0,
      height: response?.height || 0,
      icon: response?.icon,
      docModel: response?.docModel,
      sizeHeight: response?.size?.height || 64,
      sizeWidth: response?.size?.width || 64,
      propertiesTitle: response?.properties?.title,
      propertiesStatus: response?.properties?.status,
      propertiesGallery: response?.properties?.gallery,
      propertiesVideo: response?.properties?.video,
      propertiesPdf: response?.properties?.pdf,
      propertiesDescription: response?.properties?.description,
      propertiesImage: response?.properties?.image,
      latLocation: response?.location?.lat,
      longLocation: response?.location?.long,
      heightLocation: response?.location?.height
    });
    const tableData = response?.properties?.dataSource?.map(item => {
      for (const key in item) {
        if (!item[key]) {
          item[key] = 'String';
        }
      }
      if (!item.nombre) {
        item.nombre = 'String';
      }
      return item
    })
    setDataSource(tableData || []);
    if (response?.docModel === "Project") {
      setDocModelField("Project");
      form.setFieldsValue({ projectId: response?.docId })
    }
    if (response?.docModel === "Category") {
      setDocModelField("Folder");
      form.setFieldsValue({ folderId: response?.docId })
    }
    if (response?.docModel === "SubCategory") {
      setDocModelField("Sub-folder");
      form.setFieldsValue({ subFolderId: response?.docId })
    }
    if (response?.docModel === "SubCategoryChecklist") {
      setDocModelField("Sub-subfolder");
      form.setFieldsValue({ subSubFolderId: response?.docId })
    }
    if (response.orientation) {
      const orientation = response.orientation;
      form.setFieldsValue({
        heading: orientation?.heading || 0,
        pitch: orientation?.pitch || 0,
        roll: orientation?.roll || 0,
      });
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      await adminStore.getAllSubFolders(1, 100).then(res => {
        setSubCategory(res.docs)
      })
      await adminStore.getAllFolders(1, 100).then(res => {
        setFolders(res.docs);
      })
      await adminStore.getAllProjects(1, 100).then(res => {
        setProjects(res.docs);
      })
      await adminStore.getAllSubSubFolder(1, 100).then(res => {
        setSubSubFolders(res.docs);
      })
      if (pinId === "new") {
        form.resetFields();
        form.setFieldsValue({
          icon: "https://s3.eu-west-3.amazonaws.com/bacha.zenpix/Icons/icons8-home-address-96.png"
        });
      } else {
        await adminStore.getPinDetail(pinId).then((response) => {
          updateFormData(response)
        })
      }
    };
    adminStore.setSelectedKeyMenu('pins?page=1&limit=10');
    fetchData();
    return () => {
      form.resetFields();
      setSubCategory([]);
      setProjects([]);
      setFolders([]);
      setSubSubFolders([]);
      setImageUrl(null)
    };
  }, [pinId]);

  const onFinish = () => {
    form.validateFields().then((values) => {
      const payload = {
        title: values?.title,
        docModel: values?.docModel,
        docId: values?.projectId || values?.folderId || values?.subFolderId || values?.subSubFolderId,
        lat: values?.lat || 0,
        long: values?.long || 0,
        height: values?.height || 0,
        icon: values?.icon || '',
        orientation: {
          heading: values?.heading || 0,
          pitch: values?.pitch || 0,
          roll: values?.roll || 0,
        },
        location: {
          lat: values?.latLocation || 0,
          long: values?.longLocation || 0,
          height: values?.heightLocation || 0,
        },
        size: {
          height: values?.sizeHeight || 64,
          width: values?.sizeWidth || 64
        },
        properties: {
          title: values?.propertiesTitle || "",
          description: values?.propertiesDescription || "",
          image: imageUrl || "",
          status: values?.propertiesStatus || "",
          dataSource,
          gallery: values?.propertiesGallery,
          video: values?.propertiesVideo,
          pdf: values?.propertiesPdf
        }
      };
      if (pinId === "new") {
        return handleCreatePin(payload)
      } else {
        return handleUpdatePin(pinId, payload)
      }
    });
  }

  const handleCreatePin = async (payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .createNewPin(payload)
      .then((response) => {
        adminStore.setLoadingProgress(false);
        backTo()
        adminStore.openNotification('bottomRight', 'success', 'Create new pin successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const handleUpdatePin = async (id, payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updatePin(id, payload)
      .then((response) => {
        adminStore.setLoadingProgress(false);
        // backTo()
        adminStore.openNotification('bottomRight', 'success', 'Update pin successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const handleDeletePin = async () => {
    if (pinId && pinId !== "new") {
      adminStore.setLoadingProgress(true);
      await adminStore
        .deletePin(pinId)
        .then((response) => {
          adminStore.openNotification('bottomRight', 'success', 'Delete pin successfully!');
          backTo()
        })
        .catch((err) => {
          adminStore.setLoadingProgress(false);
          adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
        });
    } else {
      adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
    }
  };

  const onSelectModelChange = (value) => {
    if (value === 'Project') {
      setDocModelField("Project");
    }
    if (value === 'Category') {
      setDocModelField("Folder");
    }
    if (value === 'SubCategory') {
      setDocModelField("Sub-folder");
    }
    if (value === 'SubCategoryChecklist') {
      setDocModelField("Sub-subfolder");
    }
  }
  return (
    <AdminDashboard>
      <ContentWrapper>
        <div id="toolbar-sticky">
          <ToolbarWrapper className="sticky-children">
            <Row justify="end">
              <ToolbarButton
                className="action-btn"
                icon={<CloseCircleOutlined />}
                onClick={backTo}
              >
                CANCEL
              </ToolbarButton>
              {pinId !== "new" && (
                <ToolbarButton
                  type="danger"
                  className="action-btn"
                  icon={<DeleteOutlined />}
                  onClick={handleDeletePin}
                >
                  DELETE
                </ToolbarButton>
              )}
              <ToolbarButton
                type="primary"
                className="action-btn"
                onClick={onFinish}
                icon={
                  pinId === "new" ? (
                    <PlusCircleOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
              >
                {pinId === "new" ? "CREATE" : "SAVE"}
              </ToolbarButton>
            </Row>
          </ToolbarWrapper>
        </div>
        <MainContain>
          <TableContain>
            <Form
              name="edit-pin"
              form={form}
              layout="vertical"
              labelAlign="left"
            >
              <Row >
                <Col span={24}>
                  <Form.Item name={"title"} label="Title"
                    rules={[
                      {
                        required: true,
                        message: "Please input pin title",
                      },
                    ]}
                  >
                    <Input className="form-input" placeholder="Title" />
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <Form.Item name={"subCategoryId"} label="SubCategory">
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <SelectSearchList
                        listOption={subCategory}
                        selected={selected} setSelected={setSelected}
                        setListOption={(data) => setSubCategory(data)}
                        ispin={true}
                        nameUrl='sub-categories'
                      />
                    </Col>
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <Form.Item
                    label={"Document Model"}
                    name="docModel"
                    rules={[
                      {
                        required: true,
                        message: "Please select document model"
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select a document model"
                      options={[
                        { value: 'Project', label: 'Project' },
                        { value: 'Category', label: 'Folder' },
                        { value: 'SubCategory', label: 'Sub-folder' },
                        { value: 'SubCategoryChecklist', label: 'Sub-subfolder' }
                      ]}
                      onChange={onSelectModelChange}
                    />
                  </Form.Item>
                </Col>
                {
                  docModelField === "Project" ?
                    <Col span={24}>
                      <Form.Item
                        label={"Project"}
                        name="projectId"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select project",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder={
                            "Please select project"
                          }
                          allowClear={true}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {projects.map((v, i) => {
                            return (
                              <Option key={v.id} value={v.id}>
                                {v.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    : docModelField === "Folder" ?
                      <Col span={24}>
                        <Form.Item
                          label={"Folder"}
                          name="folderId"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select folder",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder={
                              "Please select folder"
                            }
                            allowClear={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {folders.map((v, i) => {
                              return (
                                <Option key={v.id} value={v.id}>
                                  {v.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      : docModelField === "Sub-folder" ?
                        <Col span={24}>
                          <Form.Item
                            label={"Subfolder"}
                            name="subFolderId"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please select sub-folder",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder={
                                "Please select sub-folder"
                              }
                              allowClear={true}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {subCategorys.map((v, i) => {
                                return (
                                  <Option key={v.id} value={v.id}>
                                    {v.title}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        : docModelField === "Sub-subfolder" ?
                          <Col span={24}>
                            <Form.Item
                              label={"Sub-subfolder"}
                              name="subSubFolderId"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please select sub-subfolder",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder={
                                  "Please select sub-subfolder"
                                }
                                allowClear={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {subSubFolders.map((v, i) => {
                                  return (
                                    <Option key={v.id} value={v.id}>
                                      {v.title}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col> : null
                }
                <Col span={24}>
                  <Form.Item name={"icon"} label="Icon"
                    rules={[
                      {
                        required: true,
                        message: "Please input pin url",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <GroupItem title={"Location Zoom"}>
                  <GroupItem title={"Location"}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <Form.Item name={"latLocation"} label="Lat">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <Form.Item name={"longLocation"} label="Long">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <Form.Item name={"heightLocation"} label="Height">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </GroupItem>
                  <GroupItem title={"orientation"}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <Form.Item name={"heading"} label="Heading">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <Form.Item name={"pitch"} label="Pitch">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <Form.Item name={"roll"} label="Roll">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </GroupItem>
                </GroupItem>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                    <Form.Item name={"lat"} label="Lat">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                    <Form.Item name={"long"} label="Long">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                    <Form.Item name={"height"} label="Height">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <GroupItem title={"size"}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item name={"sizeHeight"} label="Height">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item name={"sizeWidth"} label="Width">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </GroupItem>
                <GroupItem title={"properties"}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      name={["propertiesTitle"]}
                      label="title"
                    >
                      <Input
                        className="form-input"
                        placeholder="Please input properties title"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      name={["propertiesStatus"]}
                      label="status"
                    >
                      <Input
                        className="form-input"
                        placeholder="Please input properties status"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["propertiesGallery"]}
                      label="Gallery"
                    >
                      <Input
                        className="form-input"
                        placeholder="Please input properties gallery"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["propertiesVideo"]}
                      label="video"
                    >
                      <Input
                        className="form-input"
                        placeholder="Please input properties video"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["propertiesPdf"]}
                      label="pdf"
                    >
                      <Input
                        className="form-input"
                        placeholder="Please input properties pdf"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <PinTable setDataSource={setDataSource} dataSource={dataSource} ></PinTable>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["propertiesDescription"]}
                      label="description"
                    >
                      <TextArea rows={4} className="form-input" placeholder="Please input properties description" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <UploadImage imageUrl={imageUrl} setImageUrl={setImageUrl} propertiesName={'propertiesImage'} />
                  </Col>
                </GroupItem>
              </Row>
            </Form>
          </TableContain>
        </MainContain>
      </ContentWrapper >
    </AdminDashboard >
  );
};

export default inject(
  "userStore",
  "adminStore",
  "authStore"
)(observer(EditPin));
