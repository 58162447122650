import styled from "styled-components";
import { Col, Checkbox, Menu, Avatar, Row } from "antd";
export const MainMenuWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: ${props => props.open ? "240px" : 'auto'};
  z-index: 9999;
  @media only screen and (max-width: 480px) {
    width: ${props => props.open ? "50px" : 'auto'};
    top: 0;
    bottom: 80px;
    background: ${props => props.colormenu ? props.colormenu : '#00acd2'};
    float: left;
  }
  .category-icons {
    @media only screen and (max-width: 480px) {
      overflow: auto;
      height: calc(100vh - 25px);
      ::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  .ant-row {
    margin-left: 0;
    margin-right: 0;
  }
  .menu-close-btn {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    padding-top: 2px;
    cursor: pointer;
    background: ${props => props.colormenu ? props.colormenu : '#00acd2'}
  }
  .close-btn {
    height: 30px;
    padding-right: 3px;
    color: #fff;
    cursor: pointer;
  }
  .open-menu-btn {
    position: absolute;
    left: 10px;
    cursor: pointer;
    path {
      fill: #FFFFFF !important;
      transition: ease .3s;
    }
    .ant-avatar.ant-avatar-square.ant-avatar-icon {
      margin: 0px !important;
      background-color: #191919;
    }
    @media only screen and (max-width: 480px) {
      top: 15px;
    }
  }
`;

export const MainMenuItemWrapper = styled(Row)`
  position: relative;
  font-size: 0.8rem;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: #fff;
  &:hover{
    background-color:  ${props => props.colormenurgba ? props.colormenurgba : 'rgba(0, 172, 210,0.7)'};
    color: #fff;
  }
  
  & + & {
    margin-left: 0;
  }

  &:has(.ant-checkbox-checked) {
    background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'};
    color: #fff;
  }
  &:has(.ant-checkbox-checked) span {
    color: white;
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'};
    border-color: white;
  }
  &:hover .ant-checkbox-inner {
    border-color: #d9d9d9;
  }
  ${props => props.active && `background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'}; color: #fff`};
`

export const SubMenuItemWrapper = styled.div`
  @media only screen and (min-width: 481px) {
    max-height: calc(100vh - 440px) !important;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
      background: lightgrey;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #888; 
    }
  }
  @media only screen and (max-width: 480px) {
    max-height: ${props => props.maxHeight}px !important;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
      background: lightgrey;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #888; 
    }
  }
`

export const MainMenuItem = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

export const MenuItemIcon = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${props => props.actived ? props.colormenu ? props.colormenu : '#00acd2' : props.colormenurgba ? props.colormenurgba : 'rgba(0, 172, 210,0.7)'};
  padding: 10px;
  cursor: pointer;
  .title-icon {
    height: 50px;
  }
  .title-row {
    height: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
    word-wrap: break-word;
  }
  span {
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 0.8rem;
    opacity: 50%;
  }
  span.menu-title {
    overflow: hidden;
  }

  &:hover {
    background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'};
  }
  ${(props) => props.actived && `span { opacity: 100% }`}
`;

export const MenuItemIconResponsive = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${props => props.colormenurgba ? props.colormenurgba : 'rgba(0, 172, 210,0.7)'};
  padding: 5px;
  cursor: pointer;
  span {
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 0.8rem;
    margin-top: 8px;
    opacity: 50%;
  }
  &:hover {
    background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'};
  }
  ${(props) => props.actived && `background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'}; span { opacity: 100% }`}
`;

export const SubMenuResponsive = styled.div`
  display: none;
  @media only screen and (max-width: 480px) {
    display: block;
    position: absolute;
    left: 102%;
    padding: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 180px;
    .btn-close {
      display: flex;
      justify-content: flex-end;
      background: ${props => props.colormenu ? props.colormenu : '#00acd2'};
      color: #fff;
      padding: 4px 0;
      cursor: pointer;
      span {
        padding: 0 3px;
      }
    }
  }
`

export const SubMenuItemResponsive = styled.div`
  @media only screen and (max-width: 480px) {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
      background: lightgrey;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #888; 
    }
  }
`

export const CustomAntMenu = styled(Menu)`
  font-size: 0.8rem;
`;

export const SubMenuWrapper = styled.div`
  position: absolute;
  left: 102%;
  padding: 0;
  background :rgba(0, 0, 0, 0.7); 
  top: 0px;
  color: #fff;
  min-width: 65%;
    .close-btn {
      display: flex;
      justify-content: flex-end;
      background: ${props => props.colormenu ? props.colormenu : '#00acd2'};
      color: #fff;
      padding: 4px 0;
      // margin-bottom: 8px;
      span {
        padding: 0 5px;
      }
    }
  @media only screen and (max-width: 480px) {
    width: 120px;
  }
`;

export const MainMenuCheckbox = styled(Checkbox)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  font-size: 0.8rem;
  padding: 4px 8px;

  span:not(.ant-checkbox) {
    margin-right: auto;
    padding-left: 0;
  }

  & + & {
    margin-left: 0;
  }

  &:has(.ant-checkbox-checked) {
    background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'};
  }
  &:has(.ant-checkbox-checked) span {
    color: white;
  }
  &:has(.ant-checkbox-checked) .ant-checkbox-inner {
    background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'};
    border-color: white;
  }
  &:hover .ant-checkbox-inner {
    border-color: #d9d9d9;
  }
`;

export const MainMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  @media only screen and (min-width: 481px) {
    max-height: calc(100vh - 410px) !important;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
      background: lightgrey;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #888; 
    }
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
  // @media only screen and (max-width: 480px) {
  //   position: absolute;
  //   left: 102%;
  //   padding: 0;
  //   background: #fff;
  //   width: 180px;
  //   .btn-close {
  //     display: flex;
  //     justify-content: flex-end;
  //     background: ${props => props.colormenu ? props.colormenu : '#00acd2'};
  //     color: #fff;
  //     padding: 4px 0;
  //     cursor: pointer;
  //     span {
  //       padding: 0 3px;
  //     }
  //   }
  // }
`;

export const SubMenuButton = styled.div`
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.clicked && `background:${props => props.colormenu ? props.colormenu : '#00acd2'}; div${SubMenuItem}{color: white;}`}
`;

export const SubMenuItem = styled.div`
  display: flex;
  font-size: 0.8rem;
  padding: 4px 8px;
  align-items: center;
  justify-content: space-between;
  color: black;
`;

// export const SubMenuItemWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   background: white;
//   top: 0;
//   left: 100%;
//   margin-left: 5px;
//   min-width: 65%;

//   .closeSubMenu {
//     text-align: end;
//     background-color: ${props => props.colormenu ? props.colormenu : '#00acd2'};
//     color: white;
//     padding: 3px 10px 3px 3px;
//   }
// `;

export const AvatarCustom = styled(Avatar)`
  margin-right: 5px !important;
  cursor: pointer; 
  line-height: inherit !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  &:not(:first-child) {
    margin-left: 0px !important;
  }
  color: #FFFFFF !important;
  transition: ease .3s;
  path {
    fill: #FFFFFF !important;
    transition: ease .3s;
  }
`