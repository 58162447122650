import { makeAutoObservable } from "mobx";
import { UserRequest } from "../utils/requests";
class userStore {
  name = "John doe";

  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false

  setLoadingProgress = (isLoading) => {
    this.isLoading = isLoading
  }
  currentUser = false

  setUserName = (name) => {
    this.name = name;
  };

  setCurrentUser = (data) => {
    this.currentUser = data;
  };

  clearToken = () => {
    localStorage.clear();
    sessionStorage.clear();
  }

  getCurrentUser = () => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
      if (token) {
        UserRequest.getCurrentUser()
          .then(response => {
            this.setCurrentUser(response.data)
            this.setLoadingProgress(false)
            resolve(response.data)
          })
          .catch(() => {
            this.clearToken();
            this.setLoadingProgress(false)
            reject(false)
          })
          .finally(() => {
            this.setLoadingProgress(false)
          })
      } else {
        reject(false)
      }
    })
  }
}

export default userStore;