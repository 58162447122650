import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalFlyWalk = styled(Modal)`
    width : ${props => props.width ? props.width : '500px'};
    top: 10px;
    background : rgba(42,42,42,.0);
    .ant-modal-body{
        background : rgba(42,42,42,.6);
        padding : 5px 5px;
        color: #fff;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width : ${props => props.width ? props.width : '500px'};
    }
    ${'' /* .ant-modal-close-x{
        display: none;
    } */}
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
`

export const AttributeContainer = styled.div`
`

