import ReactDOM from 'react-dom/client';
import { Button, Col, Row, Slider } from "antd";
import {
  CallbackProperty,
  Cartesian3,
  Cartographic,
  Color,
  Math as MathCesium,
  Matrix4,
  ScreenSpaceEventType,
  Transforms,
} from "cesium";
import { useMediaQuery } from "react-responsive";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { clickPoint } from "../../../components/helper/CesiumUtils";
import { ModalFlyWalk } from "./style";
import NavigationArrow from '../../../components/elements/NavigationArrow';
import {
  CloseOutlined
} from "@ant-design/icons";

const Cesium = require("cesium");
const defaultDuration = 4;
function getFlagForKeyCode(keyCode) {
  switch (keyCode) {
    case "W".charCodeAt(0):
      return "moveForward";
    case "S".charCodeAt(0):
      return "moveBackward";
    case "Q".charCodeAt(0):
      return "moveUp";
    case "E".charCodeAt(0):
      return "moveDown";
    case "D".charCodeAt(0):
      return "moveRight";
    case "A".charCodeAt(0):
      return "moveLeft";
    case 38:
      return "moveForward";
    case 40:
      return "moveBackward";
    case 39:
      return "moveRight";
    case 37:
      return "moveLeft";
    default:
      return undefined;
  }
}

const FlyWalk = ({ viewer, projectStore, handler }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [points, setPoints] = useState([]);
  // const [flags, setFlags] = useState();
  function CustomLine() {
    this.positions = [];
    this.markers = [];
    this.referenceHeight = undefined
  }

  let flags = {
    looking: false,
    moveForward: false,
    moveBackward: false,
    moveUp: false,
    moveDown: false,
    moveLeft: false,
    moveRight: false,
  }

  const setFlagKey = (type, value) => flags = { ...flags, [type]: value };

  //Function add point click
  CustomLine.prototype.addPoint = function (resultClick) {
    if (resultClick) {
      var that = this;
      var position = resultClick.position;
      var n = this.positions.length;
      this.positions[n] = {
        position: position,
      };
    
      var marker = viewer.current.cesiumElement.entities.add({
        position: new CallbackProperty(function () {
          if (
            that.positions &&
            that.positions[n] &&
            that.positions[n].position
          ) {
            return that.positions[n].position;
          }
        }, false),
        point: {
          pixelSize: 8,
          color: Color.fromCssColorString("#00acd2"),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
      });
      this.markers.push(marker);
    }
  };

  //function remove point and line
  CustomLine.prototype.removeAll = function () {
    if (this.markers && this.markers.length > 0) {
      for (var i = 0; i < this.markers.length; i++) {
        if (viewer && viewer.current && viewer.current.cesiumElement)
          viewer.current.cesiumElement.entities.remove(this.markers[i]);
      }
      this.markers = [];
      this.positions = [];
      
    }
  };
  const [customLine] = useState(new CustomLine());
  var step = 0;
  function clearAll() {
    customLine.removeAll();
    customLine.referenceHeight = undefined
    setPoints([]);
    step = 0;
  }
  const getHeading = (pointA, pointB) => {
    const transform = Transforms.eastNorthUpToFixedFrame(pointA);
    const positionvector = Cartesian3.subtract(
      pointB,
      pointA,
      new Cartesian3()
    );
    const vector = Matrix4.multiplyByPointAsVector(
      Matrix4.inverse(transform, new Matrix4()),
      positionvector,
      new Cartesian3()
    );
    const direction = Cartesian3.normalize(vector, new Cartesian3());
    const heading =
      Math.atan2(direction.y, direction.x) - MathCesium.PI_OVER_TWO;
    const pitch = MathCesium.PI_OVER_TWO - MathCesium.acosClamped(direction.z);
    return { heading, pitch };
  };
  const CalPointClick = async (viewer1, resultClick) => {
    if (resultClick && resultClick.position) {
      var cartesian = resultClick.position;
      points.push(cartesian);
      if (points.length === 2) {
        var currentPosition = Cartographic.fromCartesian(points[0]);
        if (currentPosition.height < 0) {
          currentPosition.height = 0;
        }
        currentPosition.height =
          currentPosition.height < 1.5
            ? currentPosition.height + 1.5
            : currentPosition.height +1.5;
        const ellipsoid = viewer1.scene.globe.ellipsoid;
        let pointView = Cartographic.toCartesian(currentPosition, ellipsoid);
        customLine.referenceHeight = currentPosition.height
        const hpr = getHeading(points[0], points[1]);
        projectStore.setViewCameraFly(true);
        await viewer1.camera.flyTo({
          destination: pointView,
          duration: defaultDuration,
          orientation: {
            heading: -hpr.heading,
            pitch: hpr.pitch,
            roll: 0,
          },
        });
        customLine.removeAll();
      }
      //if (viewer1.requestRenderMode) { viewer1.scene.requestRender(); }
    }
  };

  const handleEnableFlyWalk = (e) => {
    const flagName = getFlagForKeyCode(e.keyCode);
    if (typeof flagName !== "undefined") {
      setFlagKey(flagName, true);
    }
  };

  const handleDisableFlyWalk = (e) => {
    const flagName = getFlagForKeyCode(e.keyCode);
    if (typeof flagName !== "undefined") {
      setFlagKey(flagName, false);
    }
  }

  const newFlyWalk = (viewer1) => {
    const scene = viewer1.scene;
    const canvas = viewer1.canvas;
    let timeLastTick = null
    const ellipsoid = scene.globe.ellipsoid;
    let startMousePosition;
    let mousePosition;
    const camera = viewer1.camera;

    var correctCurrentHeight = function (e, t1) {
      if(!customLine.referenceHeight) return
      const n = t1 || {
        heading: camera.heading,
        pitch: camera.pitch,
        roll: 0
      };
      let r;
      const { positionCartographic: t } = camera
      t.height = e + customLine.referenceHeight;
      r = Cartesian3.fromRadians(t.longitude, t.latitude, t.height);
      camera.setView({
        destination: r,
        orientation: n
      })
    }
    function onFlyWalk(clock) {
      if(!mousePosition) return;

      let t = timeLastTick ? clock.currentTime.secondsOfDay - timeLastTick.secondsOfDay : 1 / 60;
      (t <= 0 || t > 1) && (t = 1 / 60);
      const i1 = t / (1 / 60);
      timeLastTick = clock.currentTime;
      if (flags.looking) {
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;

        // Coordinate (0.0, 0.0) will be where the mouse was clicked.
        const x = (mousePosition.x - startMousePosition.x) / width;
        const y = -(mousePosition.y - startMousePosition.y) / height;
        camera.lookRight(x * projectStore.lookFactorFlyWalk);
        camera.lookUp(y * projectStore.lookFactorFlyWalk);
      }

      // Change movement speed based on the distance of the camera to the surface of the ellipsoid.
      const cameraHeight = ellipsoid.cartesianToCartographic(
        camera.position
      ).height;
      let o = viewer.current.cesiumElement.scene.globe.getHeight(ellipsoid.cartesianToCartographic(
        camera.position
      ));
      const c = {
        heading: viewer.current.cesiumElement.camera.heading,
        pitch: viewer.current.cesiumElement.camera.pitch,
        roll: 0
      }
      if(customLine.referenceHeight){
      
        (o && !false) || (o = cameraHeight - customLine.referenceHeight);
        const A = (cameraHeight - o) / 30 * i1 * projectStore.lookFactorFlyWalk
      
        let l = Math.PI / 2 - .1;
        // eslint-disable-next-line no-unused-expressions, no-sequences
        c.pitch > l && (c.pitch = l),
          viewer.current.cesiumElement.camera.setView({
            orientation: {
              heading: viewer.current.cesiumElement.camera.heading,
              pitch: 0,
              roll: 0
            }
          });
      }
      
     
      const moveRate = projectStore.lookFactorFlyWalk;
      if (flags.moveForward) {
        camera.moveForward(moveRate);
      }
      if (flags.moveBackward) {
        camera.moveBackward(moveRate);
      }
      if (flags.moveUp) {
        customLine.referenceHeight+=moveRate
        camera.moveUp(moveRate);
      }
      if (flags.moveDown) {
        customLine.referenceHeight-=moveRate
        camera.moveDown(moveRate);
      }
      if (flags.moveLeft) {
        camera.moveLeft(moveRate);
      }
      if (flags.moveRight) {
        camera.moveRight(moveRate);
      }
      
      correctCurrentHeight(o, c)
    }
    canvas.setAttribute("tabindex", "0"); // needed to aput focus on the canvas
    canvas.onclick = function () {
      canvas.focus();
    };
    // disable the default event handlers
    scene.screenSpaceCameraController.enableRotate = false;
    scene.screenSpaceCameraController.enableTranslate = false;
    scene.screenSpaceCameraController.enableZoom = false;
    scene.screenSpaceCameraController.enableTilt = false;
    scene.screenSpaceCameraController.enableLook = false;
    handler.setInputAction(function (movement) {
      setFlagKey('looking', true);
      mousePosition = startMousePosition = Cartesian3.clone(movement.position);
    }, ScreenSpaceEventType.LEFT_DOWN);

    handler.setInputAction(function (movement) {
      if (!viewer.current.cesiumElement.scene || projectStore.viewCameraFly)
        return;
      // let resultClick = clickPoint(viewer.current.cesiumElement, movement.position)
      let pickedObj = viewer.current.cesiumElement.scene.pick(
        movement.position
      );
      let cartesian;
      let resultClick;
      if (pickedObj) {
        cartesian = viewer.current.cesiumElement.scene.pickPosition(
          movement.position
        );
      }
      if (!cartesian) {
        const ray = viewer.current.cesiumElement.camera.getPickRay(
          movement.position
        );
        cartesian = viewer.current.cesiumElement.scene.globe.pick(
          ray,
          viewer.current.cesiumElement.scene
        );
      }
      if (cartesian) {
        resultClick = { position: cartesian };
      }
      if (!resultClick) return;
      if (step === 2) {
        customLine.removeAll();
      }
      if (step === 0) {
        customLine.removeAll();
        customLine.addPoint(resultClick);
      }
      if (step === 1) {
        var n = customLine.positions.length;
        if (n < 2) {
          customLine.addPoint(resultClick);
        } else {
          customLine.positions[1].position = resultClick.position;
        }
      }
      if (customLine.positions && customLine.positions.length) {
        resultClick = customLine.positions[customLine.positions.length - 1];
        if (step < 2) {
          step++;
          CalPointClick(viewer.current.cesiumElement, resultClick);
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK);

    handler.setInputAction(function (event) {
      mousePosition = event.endPosition;
    }, ScreenSpaceEventType.MOUSE_MOVE);

    handler.setInputAction(function (position) {
      setFlagKey('looking', false);
    }, ScreenSpaceEventType.LEFT_UP);

    document.addEventListener(
      "keydown",
      handleEnableFlyWalk,
      false
    );

    document.addEventListener(
      "keyup",
      handleDisableFlyWalk,
      false
    );

    viewer1.clock.onTick.addEventListener(onFlyWalk);
  };
  useEffect(() => {
    if (!viewer.current) return;
    if (!viewer.current.cesiumElement) return;
    const viewer1 = viewer.current.cesiumElement;
    if (projectStore.flyWalkMode) newFlyWalk(viewer1);
    return () => {
      if(!viewer.current) return;
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableRotate = true;
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableTranslate = true;
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableZoom = true;
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableTilt = true;
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableLook = true;
      if (
        viewer1.clock.onTick?._listeners?.filter((x) => x?.name == "onFlyWalk")
      ) {
        viewer1.clock.onTick._listeners =
          viewer1.clock.onTick?._listeners?.filter(
            (x) => x?.name != "onFlyWalk"
          );
      }
      for (var i = 0; i < points.length; i++) {
        viewer1.entities.remove(points[i]);
      }
      projectStore.setViewCameraFly(false);
      if (handler) {
        handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
        handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
        handler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
        handler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
      }
      clearAll();
      document.removeEventListener('keydown', handleEnableFlyWalk);
      document.removeEventListener('keyup', handleDisableFlyWalk);
    };
  }, []);

  useEffect(() => {
    if (!viewer.current) return;
    if (!viewer.current.cesiumElement) return;
    if (!viewer.current.cesiumElement.camera) return;
    const viewer1 = viewer.current.cesiumElement;
    if (handler) {
      handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
      handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
      handler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
      handler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
    }
    if (
      viewer1.clock.onTick?._listeners?.filter((x) => x?.name == "onFlyWalk")
    ) {
      viewer1.clock.onTick._listeners =
        viewer1.clock.onTick?._listeners?.filter((x) => x?.name != "onFlyWalk");
      newFlyWalk(viewer1);
    }
  }, [projectStore.lookFactorFlyWalk]);

  useEffect(() => {
    const handleEnableNavigation = (type) => {
      setFlagKey(type, true);
    };

    const handleDisableNavigation = (type) => {
      setFlagKey(type, false);
    };

    const naviagtionArrowPortal = ReactDOM.createRoot(document.getElementById('navigation-portal'));

    if (isMobile) {
      naviagtionArrowPortal.render(
        <NavigationArrow
          leftNavigate={{
            enable: () => handleEnableNavigation('moveLeft'),
            disable: () => handleDisableNavigation('moveLeft'),
          }}
          rightNavigate={{
            enable: () => handleEnableNavigation('moveRight'),
            disable: () => handleDisableNavigation('moveRight'),
          }}
          forwardNavigate={{
            enable: () => handleEnableNavigation('moveForward'),
            disable: () => handleDisableNavigation('moveForward'),
          }}
          backwardNavigate={{
            enable: () => handleEnableNavigation('moveBackward'),
            disable: () => handleDisableNavigation('moveBackward'),
          }}
        />
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const onChange = (value) => {
    if (typeof value !== "number") return;
    projectStore.setLookFactorFlyWalk(value);
  };

  const handleCancel = () => {
    projectStore.setViewCameraFly(false)
  };
  
  return (
    <>
      <ModalFlyWalk
        title="Walk control"
        open={
          projectStore.viewCameraFly && projectStore.viewMode === "Default mode"
        }
        footer={[
          <Button key="back" type="primary" onClick={handleCancel} icon={<CloseOutlined />}>
            Close
          </Button>
        ]}
        width={450}
        closable={false}
      >
        <table>
          <tbody>
            <tr>
              <td>Click on the Cesium display to start.</td>
            </tr>
            <tr>
              <td>W/S - Move forward/backward</td>
            </tr>
            <tr>
              <td>A/D - Move left/right</td>
            </tr>
            <tr>
              <td>Q/E - Move up/down</td>
            </tr>
            <tr>
              <td>
                Left mouse button down plus mouse move changes the look
                direction
              </td>
            </tr>
          </tbody>
        </table>
        <Row>
          <Col>Fly speed:</Col>
        </Row>
        <Row>
          <Col span={24}>
            <Slider
              min={0.01}
              max={3}
              onChange={onChange}
              value={
                typeof projectStore.lookFactorFlyWalk === "number"
                  ? projectStore.lookFactorFlyWalk
                  : 0.5
              }
              step={0.00001}
            />
          </Col>
        </Row>
      </ModalFlyWalk>
    </>
  );
};

export default inject("projectStore")(observer(FlyWalk));
