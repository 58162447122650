import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Table, message } from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GroupItem from "../../AdminDashboard/GroupItem";
import { ContentWrapper, ToolbarButton, ToolbarWrapper, MainContain, TableContain, ContainRelation, WidgetControl } from "../../AdminDashboard/style";
import ModalAddPin from "../ModalAddPin";
import AdminDashboard from "../../AdminDashboard/AdminDashboard";
import { isImage } from "../../../utils/projectLib";
import UploadImage from "../../../components/elements/UploadImage/UploadImage";
import ModalAddModel from "../ModalAddModel";

const { TextArea } = Input;
const { Option } = Select;

const EditPointOfInterest = ({ adminStore }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { pointOfInterestId } = useParams();
  const [subCategorys, setSubCategory] = useState([]);
  const [ionIds, setIonIds] = useState([]);
  const [inputIon, setInputIon] = useState(true);
  const [pins, setPins] = useState([]);
  const [model3ds, setModel3ds] = useState([]);
  const [subSubFolderDetail, setSubSubFolderDetail] = useState(null);
  const [imageUrl, setImageUrl] = useState(null)
  const [projects, setProjects] = useState([]);
  const [isSelectIon, setIsSelectIon] = useState(false);
  const [tokenIon, setTokenIon] = useState('');
  const [isToken, setIsToken] = useState(false);
  const [categories, setCategories] = useState([]);
  const findPorjectSelected = (subFolderId, projectAll, folderAll, subFolderAll) => {
    const subFolderData = subFolderAll?.find(x => x.id === subFolderId)
    let token = '';
    if (subFolderData) {
      if (subFolderData.categoryId) {
        const folderData = folderAll?.find(x => x.id === subFolderData.categoryId)
        if (folderData) {
          if (folderData.projectId) {
            const projectData = projectAll?.find(x => x.id === folderData.projectId)
            if (projectData) {
              token = projectData.token || '';
            }
          }
        }
      }
    }
    setTokenIon(token)
    return token
  }
  useEffect(() => {
    const fetchData = async () => {
      let projectAll = await adminStore.getAllProjects(1, 100);
      if (projectAll?.docs?.length > 0) {
        setProjects(projectAll.docs);
      }
      let folderAll = await adminStore.getAllFolders(1, 100)
      if (folderAll?.docs?.length > 0) {
        setCategories(folderAll.docs);
      }
      let subfolderAll = await adminStore.getAllSubFolders(1, 100)
      if (subfolderAll?.docs?.length > 0) {
        setSubCategory(subfolderAll.docs);
      }
      adminStore.getIonIds().then(res => {
        setIonIds(res);
      })
      if (pointOfInterestId && pointOfInterestId !== "new") {
        let subFolderId = '';
        await adminStore
          .getPointOfInterestById(pointOfInterestId)
          .then((response) => {
            setSubSubFolderDetail(response);
            setImageUrl(isImage(response?.properties?.image) && response?.properties?.image || null)
            subFolderId = response?.subCategoryId
            form.setFieldsValue({
              subCategoryId: response?.subCategoryId,
              order: response?.order,
              title: response?.title,
              ionAccess: response?.ionAccess,
              type: response?.type,
              height: response?.height,
              propertiesTitle: response?.properties?.title,
              propertiesDescription: response?.properties?.description,
              propertiesImage: response?.properties?.image,
              propertiesLink: response?.properties?.link,
              strokewidth: response?.stroke?.width,
              strokecolor: response?.stroke?.color,
              strokeopacity: response?.stroke?.opacity,
              fillcolor: response?.fill?.color,
              fillopacity: response?.fill?.opacity
            });
            if (response.orientation) {
              const orientation = response.orientation;
              form.setFieldsValue({
                orientationheading: orientation?.heading || 0,
                orientationpitch: orientation?.pitch || 0,
                orientationroll: orientation?.roll || 0,
                orientationlat: orientation?.lat || 0,
                orientationlon: orientation?.lon || 0,
                orientationheight: orientation?.height || 0,
              });
            }
            if (response?.type === 'place') {
              setInputIon(false);
            }
            if (response.pin) {
              setPins(response.pin || [])
            }
            setModel3ds(response.model3ds || [])
          })
          .catch((err) => {
            adminStore.setLoadingProgress(false);
            adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
          });
        let token = findPorjectSelected(subFolderId, projectAll?.docs || [], folderAll?.docs || [], subfolderAll?.docs || [])
        adminStore.getIonIds(token).then(res => {
          if (res?.length > 0) {
            setIonIds(res)
            setIsToken(true)
          } else {
            message.config({
              top: 80,
              duration: 2,
            });
            message.error("Project Ion token does not exist");
            setIsToken(false);
          }
        })
      } else {
        form.resetFields();
        setImageUrl(null)
      }
    }
    adminStore.setSelectedKeyMenu('sub-subfolders?page=1&limit=10');
    fetchData();
    return () => {
      form.resetFields()
      setSubCategory([])
      setIonIds([])
      setPins([]);
      setModel3ds([])
      setImageUrl(null)
      setIsToken(false)
      setIsSelectIon(false)
      setTokenIon('')
      setProjects([])
      setCategories([])
    }
  }, [pointOfInterestId]);
  useEffect(() => {
    if (tokenIon) {
      adminStore.getIonIds(tokenIon).then(res => {
        if (res?.length > 0) {
          setIonIds(res)
          setIsToken(true)
        } else {
          message.config({
            top: 80,
            duration: 2,
          });
          message.error("Project Ion token does not exist");
          setIsToken(false);
        }
      })
    } else {
      setIonIds([])
      setIsToken(false);
    }
  }, [tokenIon])

  const createNewPointOfInterest = async (payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore.createNewPointOfInterest(payload).then((res) => {
      adminStore.setLoadingProgress(false);
      adminStore.openNotification(
        "bottomRight",
        "success",
        "Created a new sub-subfolder successfully!"
      );
      backTo();
    })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const updatePointOfInterest = async (id, payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updatePointOfInterest(id, payload)
      .then((res) => {
        adminStore.openNotification(
          "bottomRight",
          "success",
          "Updated sub-subfolder successfully!"
        );
        adminStore.setLoadingProgress(false);
        backTo();
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      let payload = {
        subCategoryId: values?.subCategoryId || null,
        title: values?.title,
        order: values?.order < 0 ? 0 : values?.order || 0,
        ionAccess: values?.ionAccess || null,
        type: values?.type,
        height: parseFloat(values?.height || 0),
        orientation: {
          heading: values?.orientationheading || 0,
          pitch: values?.orientationpitch || 0,
          roll: values?.orientationroll || 0,
          lat: values?.orientationlat || 0,
          lon: values?.orientationlon || 0,
          height: values?.orientationheight || 0
        },
        properties: {
          title: values?.propertiesTitle,
          description: values?.propertiesDescription,
          image: values?.propertiesImage
        },
        stroke: {
          width: values?.strokewidth,
          color: values?.strokecolor,
          opacity: values?.strokeopacity
        },
        fill: {
          color: values?.fillcolor,
          opacity: values?.fillopacity
        }
      };
      if (pointOfInterestId === "new") {
        return createNewPointOfInterest(payload);
      } else {
        return updatePointOfInterest(pointOfInterestId, payload);
      }
    });
  }

  const deletePointOfInterest = async (pointOfInterestId) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deletePointOfInterest(pointOfInterestId)
      .then((response) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification(
          "bottomRight",
          "success",
          "Delete sub-subfolder successfully!"
        );
        backTo();
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification(
          "bottomRight",
          "error",
          "Something went wrong!"
        );
      });
  };
  const confirmDeleteModel = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deleteModel3DS(record.id)
      .then((response) => {
        setModel3ds(model3ds.filter(model3d => model3d.id !== record.id));
        adminStore.openNotification('bottomRight', 'success', 'Delete model3d successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  }
  const page = adminStore.currentPage;
  const limit = adminStore.currentLimit;
  const search = adminStore.searchInput;

  const backTo = () => {
    return navigate(`/admin/sub-subfolders?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`);
  };

  const onSelectChange = (value) => {
    if (value === 'place') {
      setInputIon(false);
    } else {
      setInputIon(true);
    }
  }

  const Relation = ({ title, children, action, icon, handleClick }) => {
    return (
      <div className="relation">
        <Row style={{ paddingBottom: "5px" }} justify="space-between">
          <div>{title}</div>
          {
            action && pointOfInterestId !== 'new' && (
              <Button onClick={() => handleClick()}>
                {icon}
                {action}
              </Button>
            )
          }
        </Row>
        {children}
      </div>
    );
  };

  const handleOpenAddPinModal = () => {
    adminStore.setOpenPinModal(true);
  }

  const titleOnClick = () => {
    localStorage.setItem("sub-subfolder", JSON.stringify(subSubFolderDetail));
  }

  const columnPins = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a className="relation-label" href={`/admin/pins/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this record?'}
            onConfirm={() => confirmDeletePin(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];
  const columnmodels = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a className="relation-label" href={`/admin/model3ds/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this record?'}
            onConfirm={() => confirmDeleteModel(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];
  const handleOpenAddModelModal = () => {
    adminStore.setOpenModelModal(true);
  };
  const confirmDeletePin = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore.deletePin(record.id).then((res) => {
      setPins(pins.filter(pin => pin.id !== record.id));
      adminStore.openNotification("bottomRight", "success", "Delete pin successfully!");
    })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      })
  }

  return (
    <AdminDashboard>
      <ContentWrapper>
        <div id="toolbar-sticky">
          <ToolbarWrapper className="sticky-children">
            <Row justify="end">
              <ToolbarButton
                className="action-btn"
                icon={<CloseCircleOutlined />}
                onClick={backTo}
              >
                CANCEL
              </ToolbarButton>
              {pointOfInterestId !== "new" &&
                <ToolbarButton
                  type="danger"
                  className="action-btn"
                  icon={<DeleteOutlined />}
                  onClick={() => deletePointOfInterest(pointOfInterestId)}
                >
                  DELETE
                </ToolbarButton>
              }
              <ToolbarButton
                type="primary"
                className="action-btn"
                onClick={onFinish}
                icon={
                  pointOfInterestId === "new" ? (
                    <PlusCircleOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
              >
                {pointOfInterestId === "new" ? "CREATE" : "SAVE"}
              </ToolbarButton>
            </Row>
          </ToolbarWrapper>
        </div>
        <MainContain>
          <TableContain>
            <Col span={24}>
              <Form
                form={form}
                name="edit-point-of-interests"
                layout="vertical"
                labelAlign="left"
                style={{ width: '100%' }}
              >
                <Row>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item name={["title"]} label="Title" rules={[
                          {
                            required: true,
                            message: "Please input title",
                          },
                        ]}>
                          <Input className="form-input" placeholder="Title" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={"Subfolder"}
                          name="subCategoryId"
                          rules={[
                            {
                              required: false,
                              message:
                                "Please select a subfolder",
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => findPorjectSelected(e, projects, categories, subCategorys)}
                            showSearch
                            placeholder={
                              "Please select a subfolder"
                            }
                            allowClear={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {subCategorys.map((v, i) => {
                              return (
                                <Option key={v.id} value={v.id}>
                                  {v.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name={["order"]}
                          label="Order"
                          rules={[
                            {
                              required: false
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            min={0}
                            className="form-input"
                            placeholder="Please input order number"
                          />
                        </Form.Item>
                      </Col>
                      <Row>
                        <Col span={24}>
                          <Form.Item name={'type'} label="Type"
                            rules={[
                              {
                                required: true,
                                message: "Please input ionAccess",
                              },
                            ]}
                          >
                            <Select
                              options={[
                                { value: 'ion', label: 'ION 3DTiles' },
                                { value: 'geojson', label: 'ION Geojson' },
                                { value: 'imagery', label: 'ION Imagery' },
                                { value: 'czml', label: 'ION Czml' },
                                { value: 'place', label: 'Place' }
                              ]}
                              onChange={onSelectChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {
                        inputIon && (
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                label={"ion id"}
                                name="ionAccess"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please select a ionid"
                                  },
                                ]}
                              >
                                <Select
                                  onFocus={() => {
                                    setIsSelectIon(true)
                                  }}
                                  showSearch
                                  placeholder={"Please select a ionid"}
                                  allowClear={true}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {ionIds.map((v, i) => {
                                    return (
                                      <Option key={v.id} value={v.id}>
                                        {`${v.name} (${v.id})`}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                                {!isToken && isSelectIon && <p style={{ color: 'red' }}>Project Ion token does not exist</p>}
                            </Col>
                          </Row>
                        )
                      }
                      <Col span={24}>
                        <Form.Item name={"height"} label="Height">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <GroupItem title={"orientation"}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationlat"} label="Lat">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={["orientationlon"]} label="Lon">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationheight"} label="Height">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationheading"} label="heading">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={["orientationpitch"]} label="pitch">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationroll"} label="roll">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </GroupItem>
                      <GroupItem title={"properties"}>
                        <Col span={24}>
                          <Form.Item
                            name={["propertiesTitle"]}
                            label="title"
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input properties title"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={["propertiesDescription"]}
                            label="description"
                          >
                            <TextArea rows={4} className="form-input" placeholder="Please input properties description" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <UploadImage imageUrl={imageUrl} setImageUrl={setImageUrl} propertiesName={'propertiesImage'} />
                        </Col>
                      </GroupItem>
                      <Row>
                        <Col span={24}>
                          <GroupItem title={"Stroke"}>
                            <Col span={24}>
                              <Form.Item name={"strokewidth"} label="Witdh">
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item name={["strokecolor"]} label="color">
                                <input
                                  style={{ width: '150px', height: '150px' }}
                                  type="color"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item name={"strokeopacity"} label="Opacity">
                                <InputNumber max={1} min={0} style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          </GroupItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <GroupItem title={"Fill"}>
                            <Col span={24}>
                              <Form.Item name={"fillcolor"} label="Color">
                                <input
                                  style={{ width: '150px', height: '150px' }}
                                  type="color"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item name={["fillopacity"]} label="Opacity">
                                <InputNumber max={1} min={0} style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          </GroupItem>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <ContainRelation>
                      <Row>
                        <WidgetControl span={24}>
                          <Relation handleClick={handleOpenAddModelModal} title={"Model 3D"} action={'Add Model 3D'} icon={<PlusOutlined />}
                          >
                            <Table
                              rowKey={(record) => record.id}
                              bordered
                              pagination={false}
                              columns={columnmodels}
                              dataSource={model3ds.sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)}
                              scroll={{ y: model3ds.length > 5 ? 213 : null }}
                            />
                          </Relation>
                        </WidgetControl>
                        <WidgetControl span={24}>
                          <Relation
                            handleClick={handleOpenAddPinModal}
                            title={"PIN"}
                            action={"Add pin"}
                            icon={<PlusOutlined />}
                          >
                            <Table
                              rowKey={(record) => record.id}
                              bordered
                              pagination={false}
                              columns={columnPins}
                              dataSource={pins}
                              scroll={{ y: pins.length > 5 ? 213 : null }}
                            />
                          </Relation>
                        </WidgetControl>
                      </Row>
                    </ContainRelation>
                  </Col>
                </Row>
              </Form>
            </Col>
            <ModalAddPin setPinsSubSubFolder={setPins} subSubFolderId={pointOfInterestId} />
            <ModalAddModel setModelsSubSubFolder={setModel3ds} subSubFolderId={pointOfInterestId} tokenIon={tokenIon} />
          </TableContain>
        </MainContain>
      </ContentWrapper>
    </AdminDashboard>
  );
};

export default inject("adminStore")(observer(EditPointOfInterest));
