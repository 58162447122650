import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children, authStore, userStore, ...rest }) => {
  const [userRole, setUserRole] = useState(userStore?.currentUser?.role);
  const routeRoles = rest?.meta?.roles;
  const isValidToken = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
  const history = useNavigate();

  useEffect(() => {
    if (!userStore.currentUser) {
      const fetchUserData = async () => {
        await userStore.getCurrentUser().catch(() => history('/auth/sign-in'));
      }
      // setLoading(true);
      fetchUserData().then(() => {
        setUserRole(userStore.currentUser?.role);
        // setLoading(false);
      })
    } else {
      setUserRole(userStore.currentUser?.role)
    }
  }, [])

  if (!isValidToken) {
    return <Navigate to={{ pathname: '/auth/sign-in' }} />
  }

  else if (routeRoles && userRole && routeRoles.indexOf(userRole) === -1) {
    return <Navigate to={{ pathname: '/home' }} />
  }
  
  else {
    // return loading ? <LoadingSpinner theme={"appTheme"} type={"page"} /> : children
    return  children
  }
}

export default inject("userStore", "authStore")(observer(ProtectedRoute));
