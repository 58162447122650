import axios from "axios";
import { apiUrl } from "./config";

export const isImage = async (url) => {
  // Make a HEAD request to the URL to check the Content-Type header
  return axios.head(`${apiUrl}/files/${url}`)
    .then((response) => {
      return response.headers['content-type'].startsWith('image/');
    })
    .catch((error) => {
      return false;
    });
}

export const uploadImage = async (file, token) => {
  return axios({
    method: `post`,
    url: `${apiUrl}/files/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: file,
  })
}

export const getUploadImage = async (fileName, token) => {
  return axios({
    method: `get`,
    url: `${apiUrl}/files/${fileName}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const hexToRgba = (hex, opacity) => {
  if (hex) {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  return null
}