import { CloseOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";

import { AttributeContainer, CustomModalAttribute } from "./style";
const ModalAtrribute = ({ projectStore }) => {
  const [isLoading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);

  const clickClose = () => {
    projectStore.setShowModalAtrribute(false);
  };
  useEffect(() => {
    if (projectStore.showModalAtrribute) {
      const fetchData = async () => {
        setLoading(true);
        setLoading(false);
        const data = projectStore.showModalAtrribute?.properties || {};
        let result = [];
        for (const property in data) {
          if (property !== 'dataSource') {
            result.push({
              key: property,
              value: data[property],
            });
          }
        }
        setDataTable(result);
      };
      fetchData();
    }
  }, [projectStore.showModalAtrribute]);
  return (
    <CustomModalAttribute
      width={500}
      className="modalatribute"
      title={projectStore?.showModalAtrribute?.title || 'Properties'}
      open={projectStore.showModalAtrribute}
      onCancel={() => clickClose()}
      maskClosable={false}
      footer={false}
      closeIcon={<CloseOutlined />}
      mask={false}
      destroyOnClose
    >
      <AttributeContainer>
        <Skeleton loading={isLoading} active>
          <table>
            <tbody>
              {dataTable &&
                dataTable.map((element, index) => (
                  <tr key={`${element.key}-${index}`}>
                    <td>{element.key}</td>
                    <td>{element.value}</td>
                    {/* <td>
                      {element.key === "image" ? (
                        <Image
                          width={200}
                          src={element.value}
                          preview={false}
                        />
                      ) :  element.value
                      }
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </Skeleton>
      </AttributeContainer>
    </CustomModalAttribute>
  );
};

export default inject("projectStore")(observer(ModalAtrribute));
