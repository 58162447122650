import { message } from 'antd';
import { makeAutoObservable } from 'mobx'
import { CognitoRequest } from '../utils/requests'
class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }
  token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt')
  isLoading = false
  rememberLogin = false
  setLoadingProgress = (isLoading) => {
    this.isLoading = isLoading
  }
  setRemember = (stt) => {
    this.rememberLogin = stt
  }
  setToken(token) {
    this.token = token
  }
  userLogin(email, password, remember, history) {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      CognitoRequest.login(email?.trim().toLowerCase(), password)
        .then(response => {
          if (response.data) {
            this.setRemember(remember)
            this.setToken(response.data?.IDToken)
            this.token = response.data?.IDToken
            if (remember) {
              localStorage.setItem('jwt', response.data?.IDToken)
            } else {
              sessionStorage.setItem('jwt', response.data?.IDToken)
            }
          }
          resolve(response.data)
        })
        .catch(error => {
          let errorText = error?.response?.data
          const text = errorText?.message ? errorText.message : 'Identifier or password invalid. '
          message.error(text)
          reject(error)
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    })
  }
  userRegister = (values, history) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      CognitoRequest.register(values)
        .then(response => {
          history('/auth/confirm-account')
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(err => {
          console.log(err)
          let error = err?.response?.data
          const text = error.message ? error.message : 'Something went wrong. '
          message.error(text)
          this.setLoadingProgress(false)
          reject(err.response.data)
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    })
  }
  userResetPassword = (payload, history) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      const param = {
        confirmationCode: payload?.confirmationCode,
        newPassword: payload?.newPassword,
        email: payload?.email
      }
      CognitoRequest.resetPassword(param)
        .then(response => {
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(err => {
          let error = err?.response?.data
          const text = error.message ? error.message : 'Something went wrong. '
          message.error(text)
          this.setLoadingProgress(false)
          reject(err.response.data)
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    })
  }
  userForgotPassword = (values, history) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      CognitoRequest.forgotPassword({ email: values?.email })
        .then(response => {
          history('/auth/confirm-password')
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(err => {
          let error = err?.response?.data
          const text = error.message ? error.message : 'Something went wrong. '
          message.error(text)
          this.setLoadingProgress(false)
          reject(err.response.data)
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    })
  }
  userConfirmAccount = (values, history) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      CognitoRequest.confirmAccount({ email: values?.email, confirmationCode: values.confirmationCode })
        .then(response => {
          history('/auth/sign-in')
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(err => {
          let error = err?.response?.data
          const text = error.message ? error.message : 'Something went wrong. '
          message.error(text)
          this.setLoadingProgress(false)
          reject(err.response.data)
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    })
  }
  userChangePassword = (values, history) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      CognitoRequest.changePassword(values)
        .then(response => {
          history('/auth/sign-in')
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(err => {
          let error = err?.response?.data
          const text = error.message ? error.message : 'Something went wrong. '
          message.error(text)
          this.setLoadingProgress(false)
          reject(err.response.data)
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    })
  }
}
export default AuthStore;