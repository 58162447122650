import { Row } from "antd";
import styled from "styled-components";

export const GroupItemContain = styled(Row)`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding-bottom : 15px ;
.group-contain{
  padding: 10px 20px;
  background-color: #fdfdff;
  border-radius: 5px;
  box-shadow: 0 0 4px rgb(15 34 58 / 12%);
}
.group-label {
  color: #566a7f;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  padding-bottom: 7px;
}
`;
