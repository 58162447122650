import { Checkbox, Col } from "antd";
import { inject, observer } from "mobx-react";
import {
    MinusCircleOutlined, PlusCircleOutlined
} from "@ant-design/icons";
import {
    MainMenuItemWrapper,
} from "./styled";
import { Cartesian3, Math as CesiumMath } from "cesium";
import RenderSubSubFolder from "./RenderSubSubFolder";
import { hexToRgba } from "../../../utils/projectLib";
const Cesium = require('cesium')
const defaultDuration = 4;
const RenderSubFolder = ({ projectStore, viewerRef, setActiveElementItem, element, index, checked, setShowSubMenu, isShowSubMenu, setActiveMenu, activeMenu, handleForgetCZMLAndWalk, maxHeight }) => {
    function startReRender() {
        if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement._cesiumWidget) {
            //if (viewerRef.current.cesiumElement.scene.requestRenderMode) { viewerRef.current.cesiumElement.scene.requestRender(); }
        }
    }
    const searchElement = (data, key, match) => {
        const stack = [];
        data.map(item => stack.push(item));
        while (stack.length > 0) {
            const node = stack.pop();
            if (node[key] === match) {
                return node;
            } else if (node.subcategories) {
                node.subcategories.map(child => stack.push(child))
            } else if (node.subcategorychecklists) {
                node.subcategorychecklists.map(child => stack.push(child))
            }
        }
        return null;
    }

    const handleClickPlaceMenu = (element, stt) => {
        if (!viewerRef.current) return
        if (!viewerRef.current.cesiumElement) return
        if (viewerRef.current.cesiumElement) {
            if (!element) return
            // if (element.subcategorychecklists && element.subcategorychecklists.length > 0) return
            const flyOption = {
                duration: defaultDuration,
                destination: Cartesian3.fromDegrees(element?.orientation?.lon, element?.orientation?.lat, element?.orientation?.height || 100),
                orientation: {
                    heading: element?.orientation?.heading || CesiumMath.toRadians(20),
                    pitch: element?.orientation?.pitch || CesiumMath.toRadians(-20),
                    roll: element?.orientation?.roll || CesiumMath.toRadians(-20)
                },
            };
            const viewer = viewerRef.current.cesiumElement
            viewer.scene.camera.flyTo(flyOption);
            startReRender()
            const reset = () => {
                setActiveElementItem(false)
                projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element.id));
                startReRender()
            }
            if (!stt) {
                setTimeout(reset, 100);
            }
        }
    }

    const handleClickMenu = (key, stt) => {
        const node = searchElement(projectStore.projectDetail?.categories, "id", key)
        if (node) {
            if (node.type === 'place') {
                handleClickPlaceMenu(node, stt)
            } else {
                let zoomModel
                projectStore.listModelView.map(item => {
                    if (item.key === key) {
                        zoomModel = item;
                        if (stt) zoomModel.show = true;
                    }
                })
                let orientation;
                let destination
                if (node.orientation) {
                    if (node.orientation.heading && node.orientation.pitch) {
                        orientation = {
                            heading: node.orientation.heading,
                            pitch: node.orientation.pitch,
                            roll: node.orientation.roll || 0
                        }
                    }
                    if (node.orientation.lat && node.orientation.lon && node.orientation.height) {
                        destination = Cesium.Cartesian3.fromDegrees(node.orientation.lon, node.orientation.lat, node.orientation.height)
                    }
                }
                if (zoomModel) {
                    const viewer = viewerRef.current.cesiumElement

                    if (zoomModel.modelType === 'geojson' && zoomModel.extent) {
                        const rectangle = Cesium.Rectangle.fromDegrees(
                            ...zoomModel.extent
                        );
                        const flyOption = {
                            destination: rectangle,
                            duration: defaultDuration,
                        }
                        if (orientation) {
                            flyOption.orientation = orientation;
                        }
                        if (destination) {
                            flyOption.destination = destination;
                        }
                        viewer.camera.flyTo(flyOption);

                    } else {
                        const zoomOption = { duration: defaultDuration }
                        if (zoomModel.modelType === "ion") {
                            zoomOption.offset = new Cesium.HeadingPitchRange(
                                0.0,
                                -0.5,
                                zoomModel.boundingSphere.radius * 4.0
                            )
                        }
                        if (orientation) {
                            if (zoomModel?.boundingSphere) {
                                const cartographic = Cesium.Cartographic.fromCartesian(
                                    zoomModel.boundingSphere.center
                                );
                                const flyOption = {
                                    duration: defaultDuration,
                                    destination: Cartesian3.fromDegrees(
                                        Cesium.Math.toDegrees(cartographic.longitude),
                                        Cesium.Math.toDegrees(cartographic.latitude),
                                        cartographic?.height || 100
                                    ),
                                    orientation
                                };

                                if (destination) {
                                    flyOption.destination = destination;
                                }
                                viewer.scene.camera.flyTo(flyOption);
                            } else if (node.orientation.lon && node.orientation.lat) {
                                const flyOption = {
                                    duration: defaultDuration,
                                    destination: Cartesian3.fromDegrees(node?.orientation?.lon, node?.orientation?.lat, node?.orientation?.height || 100),
                                    orientation: {
                                        heading: orientation.heading || CesiumMath.toRadians(20),
                                        pitch: orientation.pitch || CesiumMath.toRadians(-20),
                                        roll: orientation.roll || CesiumMath.toRadians(-20)
                                    },
                                };
                                viewer.scene.camera.flyTo(flyOption);
                            }
                        } else {
                            viewer.flyTo(zoomModel, zoomOption);
                        }
                        // if (orientation) {
                        //   viewer.camera.setView({ orientation });
                        // }
                        // zoomModel.show = true
                        const reset = () => {
                            setActiveElementItem(false)
                            projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element.id));
                            zoomModel.show = false
                            startReRender()
                        }
                        if (!stt) {
                            setTimeout(reset, 100);
                        }
                    }
                }
            }
        }
    }

    return (
        <>
            <MainMenuItemWrapper
                justify='space-between'
                key={`${element.title}-${index}`}
                colormenu={projectStore.projectDetail.colormenu}
                colormenurgba={hexToRgba(projectStore.projectDetail.colormenu, 0.7)}
                onClick={() => {
                    if (element.subcategorychecklists && element.subcategorychecklists.length > 0) {
                        setActiveElementItem(element.id)
                    } else {
                        if (activeMenu) {
                            if (activeMenu === element.id) {
                                // setActiveMenu(false)
                                // projectStore.setRightData([])
                            } else {
                                // setActiveMenu(element.id)
                            }
                        } else {
                            // setActiveMenu(element.id)
                        }
                    }
                    handleForgetCZMLAndWalk()
                }
                }
                active={activeMenu === element.id ? 1 : 0}
            >
                <Col
                    onClick={() => {
                        if (!element.subcategorychecklists || element.subcategorychecklists.length === 0) {
                            // projectStore.setRightData(getDescription(element))
                        }
                        handleClickMenu(element.id, projectStore.selectedMenus.includes(element.id))
                    }}
                    span={18} style={{ wordBreak: "break-word" }}>{element.title}
                </Col>
                <Col style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    {
                        element.subcategorychecklists && element.subcategorychecklists.length > 0 ? (
                            <>
                                {(element?.pin?.length > 0 || element?.model3ds?.length > 0) && <Checkbox
                                    style={{ paddingRight: '3px' }}
                                    onClick={() => {
                                        setActiveElementItem(element.id)
                                        setActiveMenu(false)
                                    }}
                                    checked={projectStore.selectedMenus.includes(element.id)}
                                    onChange={(e) => {
                                        setShowSubMenu(false);
                                        let selectedMenus = projectStore.selectedMenus
                                        if (e.target.checked) {
                                            projectStore.setSelectedMenus([...selectedMenus, element.id])
                                        } else {
                                            projectStore.setSelectedMenus(selectedMenus.filter(x => x !== element?.id));
                                        }
                                        const pinKey = element.id + '-pinLocation';
                                        let pinModel = projectStore.listPinModelViews.filter(c => c.key.includes(pinKey))
                                        if (projectStore.listModelViews?.length > 0) {
                                            let nodeModelsShow = projectStore.listModelViews.filter(c => c?.key?.includes(element.id))
                                            if (nodeModelsShow?.length > 0) {
                                                nodeModelsShow.map(model => {
                                                    model.show = e.target.checked
                                                })
                                            }
                                            let nodeModelsHide = projectStore.listModelViews.filte(c => !c?.key?.includes(element.id))
                                            if (nodeModelsHide?.length > 0) {
                                                nodeModelsHide.map(model => {
                                                    model.show = false
                                                })
                                            }
                                        }
                                        if (pinModel.length > 0) {
                                            pinModel.map(pinData => {
                                                pinData.show = e.target.checked
                                                if (pinData?.entityCollection?.values) {
                                                    pinData?.entityCollection?.values.map(c => {
                                                        if (c?.key?.includes(pinKey)) {
                                                            c.billboard.show = e.target.checked
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    }}
                                />}
                                {
                                    isShowSubMenu && element.id === checked ?
                                        <MinusCircleOutlined onClick={() => {
                                            setActiveElementItem(false)
                                            setShowSubMenu(false)
                                            setActiveMenu(false)
                                        }
                                        } style={{ fontSize: "16px" }} /> : <PlusCircleOutlined onClick={() => {
                                            setShowSubMenu(true)
                                            setActiveMenu(element.id)
                                        }}
                                            style={{ fontSize: "16px" }} />
                                }
                            </>
                        ) :
                            (element?.pin?.length > 0 || element?.model3ds?.length > 0) && (
                                <Checkbox
                                    onClick={() => {
                                        setActiveElementItem(element.id)
                                        setActiveMenu(false)
                                    }}
                                    checked={projectStore.selectedMenus.includes(element.id)}
                                    onChange={(e) => {
                                        setShowSubMenu(false);
                                        let selectedMenus = projectStore.selectedMenus
                                        if (e.target.checked) {
                                            projectStore.setSelectedMenus([...selectedMenus, element.id])
                                        } else {
                                            setActiveElementItem(false)
                                            projectStore.setSelectedMenus(selectedMenus.filter(x => x !== element?.id));
                                        }
                                        const pinKey = element.id + '-pinLocation';
                                        let pinModel = projectStore.listPinModelViews.filter(c => c.key.includes(pinKey))
                                        if (projectStore.listModelViews?.length > 0) {
                                            let nodeModelsShow = projectStore.listModelViews.filter(c => c?.key?.includes(element.id))
                                            if (nodeModelsShow?.length > 0) {
                                                nodeModelsShow.map(model => {
                                                    model.show = e.target.checked
                                                })
                                            }
                                        }
                                        if (pinModel.length > 0) {
                                            pinModel.map(pinData => {
                                                pinData.show = e.target.checked
                                                if (pinData?.entityCollection?.values) {
                                                    pinData?.entityCollection?.values.map(c => {
                                                        if (c?.key?.includes(pinKey)) {
                                                            c.billboard.show = e.target.checked
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    }}
                                />
                            )

                    }
                </Col>
            </MainMenuItemWrapper>
            {isShowSubMenu && element.subcategorychecklists && element.subcategorychecklists?.length > 0 &&
                projectStore.activeElementItem === element.id && (
                    <RenderSubSubFolder
                        setShowSubMenu={setShowSubMenu}
                        isShowSubMenu={isShowSubMenu}
                        element={element}
                        viewerRef={viewerRef}
                        projectStore={projectStore}
                        maxHeight={maxHeight}
                        setActiveMenu={setActiveMenu}
                    ></RenderSubSubFolder>
                )
            }
        </>
    )
}
export default inject("projectStore")(observer(RenderSubFolder));