import { Col, Image, Row, Skeleton, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import {
  CloseOutlined,
  GlobalOutlined, MenuUnfoldOutlined, ShopOutlined
} from "@ant-design/icons";
import { useState } from "react";
import {
  AvatarCustom,
  MainMenuContent,
  MainMenuItem,
  MainMenuWrapper,
  MenuItemIcon,
  SubMenuItemResponsive,
  SubMenuResponsive
} from "./styled";
import { Cartesian3, Math as CesiumMath } from "cesium";
import { useMediaQuery } from "react-responsive";
import RendeSubFolder from "./RendeSubFolder";
import { hexToRgba } from "../../../utils/projectLib";
const Cesium = require('cesium')
const defaultDuration = 4;
const MenuIcon = ({
  title,
  actived,
  element,
  projectStore,
  handleForgetCZMLAndWalk,
  handleClickCategory,
  menuLength,
  activeCategory,
  setActiveCategory,
  setShowMenu,
  isShowMenu
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  return (
    <Tooltip placement="right" title={title} zIndex={9999} overlayStyle={{ display: isMobile ? "block" : "none" }} destroyTooltipOnHide={true}>
      <MenuItemIcon
        colormenurgba={hexToRgba(projectStore.projectDetail.colormenu, 0.7)}
        colormenu={projectStore.projectDetail.colormenu}
        actived={actived}
        span={menuLength && menuLength > 1 && !isMobile ? 12 : 24}
        onClick={() => {
          setShowMenu(!isShowMenu)
          projectStore.setActiveElement(element)
          projectStore.setRightData([])
          handleForgetCZMLAndWalk()
          handleClickCategory(element, activeCategory ? (activeCategory && activeCategory.id === element.id ? false : element) : element, activeCategory.id === element.id ? activeCategory : false)
          setActiveCategory(activeCategory ? (activeCategory && activeCategory.id === element.id ? false : element) : element)
        }}
      >
        {/* <Row className="title-icon" style={isMobile ? {} : {display: 'none'}}>
          {icon}
        </Row> */}
        <Row className="title-row">
          <span className="menu-title">{title}</span>
        </Row>
      </MenuItemIcon>
    </Tooltip>
  );
};

const RenderFolder = ({ projectStore, viewerRef, subCategories, setSubCategories }) => {
  const projectDetail = projectStore.projectDetail;
  const [isShowSubMenu, setShowSubMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [isShowMenu, setShowMenu] = useState(false);
  const [activeCategory, setActiveCategory] = useState(false);

  const styleIcon = { fontSize: "30px", margin: "10px" }

  const removeCZML = (add) => {
    if (!viewerRef?.current?.cesiumElement) return;
    const viewer = viewerRef.current.cesiumElement;
    viewer.clock.shouldAnimate = false;
    viewer.trackedEntity = undefined;
    if (viewer.clock.onTick?._listeners?.filter((x) => x?.name == add)) {
      viewer.clock.onTick._listeners = viewer.clock.onTick?._listeners?.filter(
        (x) => x?.name != add
      );
    }
  };

  const handleForgetCZMLAndWalk = () => {
    if (projectStore.volautoMode) {
      removeCZML("onVolauto");
      projectStore.setAssignVolautoMode("startCZML", false);
      projectStore.setAssignVolautoMode("pauseCZML", false);
      projectStore.setVolautoMode(false);
    }
    if (projectStore.flyWalkMode) {
      projectStore.setViewMode("Default mode");
      projectStore.setFlyWalkMode(false);
    }
  };

  const handleClickCategory = (payload, isShow) => {
    const subData = Array.isArray(projectStore.activeElement?.subcategories) ?
      JSON.parse(JSON.stringify(projectStore.activeElement?.subcategories))?.sort((a, b) => (a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)) : [];
    setSubCategories(subData)
    const viewer = viewerRef.current.cesiumElement
    if (payload && payload.zoomTo) {
      if (!viewerRef.current) return
      if (!viewerRef.current.cesiumElement) return
      let zoomModel
      projectStore.visibleModels.map(item => {
        if (item?.id === payload.zoomTo) {
          zoomModel = projectStore.listModelView?.find(x => x?.key === item.id);
        }
      })
      if (zoomModel) {
        if (zoomModel.modelType === 'geojson' && zoomModel.extent) {
          const rectangle = Cesium.Rectangle.fromDegrees(
            ...zoomModel.extent
          );
          viewer.camera.flyTo({
            destination: rectangle,
            duration: defaultDuration,
          });
        }
        else {
          viewer.camera.flyTo(zoomModel, {
            duration: 2,
          });
        }
      }
    }
    if ((payload.lat || payload.lat === 0) && (payload.long || payload.long === 0)) {
      const flyOption = {
        duration: defaultDuration,
        destination: Cartesian3.fromDegrees(payload?.long, payload?.lat, payload?.height || 100),
        orientation: {
          heading: payload?.heading || CesiumMath.toRadians(20),
          pitch: payload?.pitch || CesiumMath.toRadians(-20),
          roll: payload?.roll || CesiumMath.toRadians(-20)
        },
      };
      viewer.camera.flyTo(flyOption);
    }
    let selectedMenus = [];
    if (payload.subcategories?.length > 0) {
      if (isShow) {
        payload.subcategories.map(subitem => {
          selectedMenus.push(subitem.id);
          if (subitem.subcategorychecklists.length > 0) {
            subitem.subcategorychecklists.map(subsubitem => {
              selectedMenus.push(subsubitem.id);
            })
          }
        })
      } else {
        payload.subcategories.map(subitem => {
          selectedMenus = selectedMenus.filter(x => x !== subitem.id);
          if (subitem.subcategorychecklists.length > 0) {
            subitem.subcategorychecklists.map(subsubitem => {
              selectedMenus = selectedMenus.filter(x => x !== subsubitem.id);
            })
          }
        })
      }
      projectStore.setSelectedMenus(selectedMenus)
    }
    if (projectStore.listModelViews?.length > 0) {
      let nodeModelsShow = projectStore.listModelViews.filter(c => c?.key?.includes(payload.id))
      if (nodeModelsShow?.length > 0) {
        nodeModelsShow.map(model => {
          model.show = isShow ? true : false
        })
      }
      let nodeModelsHide = projectStore.listModelViews.filter(c => !c?.key?.includes(payload.id))
      if (nodeModelsHide?.length > 0) {
        nodeModelsHide.map(model => {
          model.show = false
        })
      }
    }
    const pinKey = payload.id + '-pinLocation';
    const listPinKeys = selectedMenus.map(x => {
      return x + '-pinLocation';
    })
    listPinKeys.push(pinKey);
    let pinModelShow = projectStore.listPinModelViews.filter(c => {
      return listPinKeys.some(key => c.key.includes(key));
    });
    let pinModelHide = projectStore.listPinModelViews.filter(c => {
      return listPinKeys.some(key => !c.key.includes(key));
    });
    if (pinModelHide?.length > 0) {
      pinModelHide.map((pinData) => {
        pinData.show = false
        if (pinData?.entityCollection?.values) {
          pinData?.entityCollection?.values.map(c => {
            if (c?.key?.includes(pinKey)) {
              c.billboard.show = false
            }
          })
        }
      })
    }
    if (pinModelShow?.length > 0) {
      pinModelShow.map((pinData) => {
        pinData.show = isShow ? true : false
        if (pinData?.entityCollection?.values) {
          pinData?.entityCollection?.values.map(c => {
            if (c?.key?.includes(pinKey)) {
              c.billboard.show = isShow ? true : false
            }
          })
        }
      })
    }
  }

  const renderIcon = (key) => {
    let entities
    switch (key) {
      case "GlobalOutlined":
        entities = <GlobalOutlined style={styleIcon} />
        break;
      case "ShopOutlined":
        entities = <ShopOutlined style={styleIcon} />
        break;
      default:
        entities = <GlobalOutlined style={styleIcon} />
        break;
    }
    return entities
  }

  const setTop = () => {
    let valueTop = 0;
    for (let i = 0; i < projectDetail?.categories?.length; i++) {
      if (projectStore?.activeElement?.id === projectDetail?.categories[i]?.id) {
        valueTop = window.innerHeight - (25 + i * 70);
      }
    }
    return valueTop;
  }

  const setHeight = () => {
    let maxHeight = 0;
    for (let i = 0; i < projectDetail?.categories?.length; i++) {
      if (projectStore?.activeElement?.id === projectDetail?.categories[i]?.id) {
        maxHeight = window.innerHeight - (25 + i * 60 + 30);
      }
    }
    return maxHeight;
  }

  return (
    <MainMenuWrapper open={projectStore.showMainMenu ? 1 : 0} colormenu={projectStore.projectDetail.colormenu}>
      {
        projectStore.showMainMenu ? (
          <>
            <Row align="middle" justify="center">
              <Skeleton loading={projectStore.isLoading} active>
                <div className="menu-close-btn">
                  <Tooltip title='Close' placement="right">
                    <div onClick={() => projectStore.setShowMainMenu(false)} className="close-btn"><CloseOutlined /></div>
                  </Tooltip>
                </div>
                <MainMenuItem span={24} colormenu={projectStore.projectDetail.colormenu}>
                  {
                    projectDetail?.thumnail && (
                      <Image preview={false} src={projectDetail?.thumnail} width={280} height={130} alt="logo" />
                    )
                  }
                </MainMenuItem>
              </Skeleton>
              {
                projectDetail && projectDetail.categories && projectDetail?.categories?.length > 0 && (
                  <>
                    <Col span={24} className="category-icons">
                      <Row>
                        {
                          projectStore.categories.map((element) => (
                            <MenuIcon
                              colormenu={projectStore.projectDetail.colormenu}
                              key={element.title}
                              projectStore={projectStore}
                              element={element}
                              title={element.title}
                              actived={projectStore?.activeElement?.id === element.id ? 1 : 0}
                              icon={renderIcon(element.icon)}
                              viewerRef={viewerRef}
                              handleForgetCZMLAndWalk={handleForgetCZMLAndWalk}
                              handleClickCategory={handleClickCategory}
                              activeCategory={activeCategory}
                              setActiveCategory={(e) => setActiveCategory(e)}
                              menuLength={projectDetail?.categories?.length}
                              setShowMenu={setShowMenu}
                              isShowMenu={isShowMenu}
                            />
                          ))
                        }
                      </Row>
                    </Col>
                    <Col span={24}>
                      <SubMenuResponsive style={{ top: -setTop() }} colormenu={projectStore.projectDetail.colormenu}>
                        {
                          isShowMenu && subCategories &&
                          subCategories?.length > 0 &&
                          <Col className="btn-close" span={24}>
                            <Tooltip title='Close' placement="right">
                              <span onClick={() => { setShowMenu(false) }}><CloseOutlined /></span>
                            </Tooltip>
                          </Col>
                        }
                        <SubMenuItemResponsive style={{ maxHeight: setHeight() }} colormenu={projectStore.projectDetail.colormenu}>
                          {
                            isShowMenu && subCategories && subCategories?.length > 0 &&
                            subCategories.map((element, index) => (
                              <RendeSubFolder projectStore={projectStore}
                                setActiveElementItem={e => projectStore.setActiveElementItem(e)}
                                key={`${element.title}-${index}`}
                                checked={projectStore.activeElementItem}
                                element={element}
                                isShowSubMenu={isShowSubMenu}
                                setShowSubMenu={(data) => { setShowSubMenu(data) }}
                                index={index}
                                viewerRef={viewerRef}
                                setActiveMenu={e => setActiveMenu(e)}
                                activeMenu={activeMenu}
                                ter
                                handleForgetCZMLAndWalk={handleForgetCZMLAndWalk}
                                maxHeight={setHeight}
                              >
                              </RendeSubFolder>
                            ))
                          }
                        </SubMenuItemResponsive>
                      </SubMenuResponsive>
                      {
                        subCategories && subCategories?.length > 0 && (
                          <MainMenuContent colormenu={projectStore.projectDetail.colormenu}>
                            {
                              subCategories.map((element, index) => (
                                <RendeSubFolder projectStore={projectStore}
                                  setActiveElementItem={e => projectStore.setActiveElementItem(e)}
                                  key={`${element.title}-${index}`}
                                  checked={projectStore.activeElementItem}
                                  element={element}
                                  isShowSubMenu={isShowSubMenu}
                                  setShowSubMenu={(data) => { setShowSubMenu(data) }}
                                  index={index}
                                  viewerRef={viewerRef}
                                  setActiveMenu={e => setActiveMenu(e)}
                                  activeMenu={activeMenu}
                                  ter
                                  handleForgetCZMLAndWalk={handleForgetCZMLAndWalk}
                                  maxHeight={setHeight}
                                >
                                </RendeSubFolder>
                              ))
                            }
                          </MainMenuContent>
                        )
                      }
                    </Col>
                  </>
                )
              }
            </Row>
          </>
        ) : (
          <div className="open-menu-btn"
            onClick={() => {
              if (projectDetail) {
                projectStore.setShowMainMenu(true)
              }
            }}
          >
            <Tooltip title={'Menu'} placement="right">
              <AvatarCustom
                colormenu={projectStore.projectDetail.colormenu}
                shape="square"
                icon={<MenuUnfoldOutlined />}
                onClick={() => { }}
              />
            </Tooltip>
          </div>
        )
      }
    </MainMenuWrapper>
  );
};

export default inject("projectStore")(observer(RenderFolder));